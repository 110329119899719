import { RNS3 } from 'react-native-aws3';
import AppConfig from '../config/AppConfig';
let _instance;
let S3_CLIENT_OPTIONS = {
    region: AppConfig.config.external['s3.client.region'],
    accessKey: AppConfig.config.external['s3.client.accessKey'],
    secretKey: AppConfig.config.external['s3.client.secretKey'],
    successActionStatus: AppConfig.config.external['s3.client.successActionStatus'],
    bucket: AppConfig.config.external['s3.bucket.name'],
};
class S3ManagerInstance {

    constructor() {
        if(_instance) {
            return _instance;
        }
        _instance = this;
    }

    uploadChatAvatar=(file)=>{
        return this.uploadToS3(file,'chatBot/')
    };

    uploadRevampIcons=(file)=>{
        return this.uploadToS3(file,'revamp/')
    };

    uploadToS3=(file, keyPrefix)=>{

        const options = {...S3_CLIENT_OPTIONS, keyPrefix};
        return RNS3.put(file, options)
            .then(response=>{
                return {
                    response: response.body.postResponse,
                    success: response.status===201
                };
            }).catch(err=>{
                 console.log('Failed to upload media to s3 bucket');
                 console.log(err);
            });
    };
}
export const S3MediaManager = new S3ManagerInstance();
