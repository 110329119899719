import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import ConversationService from "../../../../services/ConversationService";
import { AlertUtil } from "../../../../utilities/AlertUtil";
import Button from "@material-ui/core/Button";
import { Loader } from "../../../shared/loader";
import "./evaluation.scss";
import {
  BUILDER_CONSTANTS, DEFAULT_AVATAR_URL, EVALUATION_STATES,
  S3_BUCKET_LINK,
} from "../../../../constants/CommonConstants";
import JssProvider from "react-jss/lib/JssProvider";
import Header from "../../../../layout/Header";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { createGenerateClassName } from "@material-ui/styles";
import { DebounceInput } from "react-debounce-input";
import InputBase from "@material-ui/core/InputBase";
import EditIcon from "@material-ui/icons/Edit";
import DataTable from "react-data-table-component";
import TopSectionComponent from "../../../shared/top-section/TopSectionComponent";
import { connectProfile } from "../../../../redux/modules/profile/connectProfile";
import Avatar from "@material-ui/core/es/Avatar/Avatar";
import moment from "moment";
import AddEditEvaluationModal from "../modal/AddEditEvaluationModal";
import VisibilityIcon from "@material-ui/icons/Visibility"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: "c",
  seed: "app",
});

class EvaluationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      evaluationList: [],
      editMode: false,
      openModal: false,
      evaluationId: null,
      selectedEvaluation: null,
      searchQuery: "",
      status: EVALUATION_STATES.ACTIVE,
      pageSize: 10,
      currentPage: 1,
      reset: false,
      dataLoading: true,
      sortBy: [],
      orderBy: "",
      columns: [
        {
          name: "Avatar",
          cell: (row) => (
            <Avatar
              className="avatar-img"
              alt="Avatar"
              size="40"
              src={row.avatar ? S3_BUCKET_LINK + row.avatar : S3_BUCKET_LINK + DEFAULT_AVATAR_URL}
            />
          ),
        },
        {
          name: "Name",
          selector: (row) => row?.name || "N/A",
          sortable: false,
        },
        {
          format: (row) =>
            row.rejectionReasonTemplate &&
            row.rejectionReasonTemplate.length > 0
              ? row.rejectionReasonTemplate.map(
                  (rejectionReasonTemplate, index) => {
                    return (
                      <span className="value-with-bg" key={index}>
                        {rejectionReasonTemplate}
                      </span>
                    );
                  }
                )
              : "N/A",
          name: "Rejection Reasons",
          selector: (row) => row?.rejectionReasonTemplate || "",
          sortable: false,
        },
        {
          name: "Appointments Count",
          selector: (row) => row?.appointmentsCount || "N/A",
          sortable: false,
        },
        {
          name: "Dct Count",
          selector: (row) => row?.dctCount?.toString() || "N/A",
          sortable: false,
        },
        {
          name: "Description",
          selector: (row) => row?.description || "N/A",
          sortable: false,
        },

        {
          name: "Version",
          selector: (row) => row?.version || "N/A",
          sortable: false,
        },
        {
          name: "Last Update",
          selector: (row) =>
            moment(row?.lastUpdated).format("YYYY-MM-DD") || "N/A",
          sortable: false,
        },
        {
          name: "Status",
          selector: (row) => row?.status || "N/A",
          sortable: false,
        },
        {
          name: "Actions",
          cell: (row) => (
            <div className="buttons-Wrapper">
              <Button
                className="edit-OutLined-btn"
                variant="outlined"
                onClick={() => {
                  this.openAddEditEvaluationModal(true, row);
                }}
              >
                <EditIcon style={{ color: "#0091F1" }} color="action" />
              </Button>
              <Button
                className="edit-OutLined-btn"
                variant="outlined"
                onClick={() => {
                  this.editEvaluation(row);
                }}
              >
                <VisibilityIcon style={{color: "#0091F1"}} color="action"/>
              </Button>
            </div>
          ),
        },
      ],
    };
    this.form = {};
  }

  componentDidMount = async () => {
    this.getEvaluationList();
  };

  /**
   * @function getEvaluationList
   * @description This method is used to get engagement Levels list.
   */

  getEvaluationList = async () => {
    const { searchQuery, currentPage, pageSize, orderBy, sortBy, status } =
      this.state;
    try {
      const response = await ConversationService.getEvaluationList(
        searchQuery?.trim(),
        currentPage - 1,
        pageSize,
        orderBy,
        sortBy,
        status
      );

      if (response?.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          dataLoading: false,
        });
      } else {
        this.setState({
          evaluationList: response?.EvaluationSummaryList,
          totalPages: response?.totalPages,
          totalRecords: response?.totalRecords,
          dataLoading: false,
        });
      }
    } catch (e) {
      AlertUtil.showError("Whoops!Something went wrong.Please try again.");
      this.setState({
        dataLoading: false,
      });
    }
  };

  /**
   * @function openAddEditEvaluationModal
   * @description This method is used to open add/edit model .
   */
  openAddEditEvaluationModal = (editMode, selectedEvaluation) => {
    this.setState({
      editMode,
      selectedEvaluation: selectedEvaluation,
      evaluationId: selectedEvaluation?.evaluationId,
      openModal: true,
    });
  };

  /**
   * @function closeAddEditEvaluationModal
   * @description This method is used to close add/edit modal .
   */
  closeAddEditEvaluationModal = () => {
    this.setState({ openModal: false });
  };

  /**
   * @function saveEvaluation
   * @description This method is used to save Evaluation
   */
  saveEvaluation = async (selectedEvaluationRequest, editMode) => {
    try {
      this.setState({
        isLoading: true,
      });
      const { evaluationId } = this.state;

      const requestPayload = {
        avatar: selectedEvaluationRequest?.avatar,
        contentBlocks: selectedEvaluationRequest?.contentBlocks,
        description: selectedEvaluationRequest?.description,
        name: selectedEvaluationRequest?.name,
        rejectionTemplate: selectedEvaluationRequest?.rejectionTemplate,
        reorder: selectedEvaluationRequest?.reorder || false,
      };

      let selectedEvaluationCall = ConversationService.addEvaluation;
      if (editMode) {
        selectedEvaluationCall = ConversationService.updateEvaluation;
      }
      const response = await selectedEvaluationCall(
        requestPayload,
        evaluationId
      );

      if (response?.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          isLoading: false,
          openModal: false,
        });
      } else {
        AlertUtil.showSuccess(
          `Evaluation ${editMode ? "updated" : "added"} successfully`
        );
        this.setState({
          isLoading: false,
          openModal: false,
        });
        await this.getEvaluationList();
      }
    } catch (e) {
      AlertUtil.showError("Whoops! Something went wrong.Please try again.");
      this.setState({
        isLoading: false,
        openModal: false,
      });
    }
  };

  /**
   * @function getFieldText
   * @description This method is used to get Field text.
   */
  getFieldText = (fieldName) => {
    switch (fieldName) {
      case "Name":
        return "name";
      default:
        return fieldName;
    }
  };

  handlePageChange = (page) => {
    this.setState(
      {
        currentPage: page,
      },
      this.getEvaluationList
    );
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState(
      {
        pageSize: newPerPage,
      },
      this.getEvaluationList
    );
  };

  /**
   * @function editEvaluation
   * @description This method is used to navigate to edit conversation/evaluation screen.
   */
  editEvaluation = (evaluation) => {
    this.props.history.push(
      `/admin/conversation-builder/conversation/edit/${evaluation?.evaluationId}`,
      {
        conversation: evaluation,
        conversationId: evaluation?.evaluationId,
      }
    );
  };

  /**
   * @function onSort
   * @description This method is used to sort field values from BE .
   */
  onSort = async (column, sortDirection) => {
    let orderBy = BUILDER_CONSTANTS.SORT_DIRECTIONS[sortDirection];
    const sortBy = this.getFieldText(column.name);
    orderBy = sortBy.length > 0 ? orderBy : "";
    this.setState(
      {
        sortBy,
        orderBy,
      },
      () => {
        this.getEvaluationList();
      }
    );
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    }
    const {
      reset,
      searchQuery,
      columns,
      evaluationList,
      totalRecords,
      dataLoading,
      openModal,
      currentPage,
      editMode,
      selectedEvaluation,
      status
    } = this.state;
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <div className="main-body-c">
            <Header />
            <TopSectionComponent
              title={`Evaluations ${status  === EVALUATION_STATES.DRAFT ? 'Drafts' : ''} `}
              actionButtons={[
                {
                  text: "Add Evaluation",
                  onClick: () => {
                    this.openAddEditEvaluationModal(false, null);
                  },
                },
                {
                  text: `View ${status === EVALUATION_STATES.DRAFT ? 'Active' : 'Drafts' } `,
                  onClick: () => {
                    this.setState(
                      {
                        status:
                          status === EVALUATION_STATES.DRAFT
                            ? EVALUATION_STATES.ACTIVE
                            : EVALUATION_STATES.DRAFT,
                      },
                      () => {
                        this.getEvaluationList();
                      }
                    );
                  },
                },
              ]}
            />

            <div className="content-main">
              <div className="table-actions">
                <div className="search-main">
                  <DebounceInput
                    element={(props) => {
                      return (
                        <InputBase
                          className="search-input"
                          placeholder="Search Evaluation by Name "
                          autoFocus
                          label="Search Evaluation by Name"
                          variant="filled"
                          value={props.value}
                          onChange={props.onChange}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon style={{ color: "#475885" }} />
                            </InputAdornment>
                          }
                        />
                      );
                    }}
                    className="search-input"
                    minLength={1}
                    debounceTimeout={500}
                    value={searchQuery}
                    onChange={(event) =>
                      this.setState(
                        {
                          searchQuery: event.target.value,
                          currentPage: 1,
                        },
                        async () => {
                          await this.getEvaluationList();
                          this.setState({
                            reset: !reset,
                          });
                        }
                      )
                    }
                  />
                </div>
              </div>
              <Paper className="table-component-main" elevation={2}>
                <DataTable
                  columns={columns}
                  data={evaluationList}
                  progressPending={dataLoading}
                  persistTableHead
                  keyField={"id"}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRecords}
                  paginationDefaultPage={currentPage}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  paginationResetDefaultPage={reset}
                  sortServer={true}
                  onSort={this.onSort}
                />
              </Paper>
            </div>
          </div>
          {openModal && (
            <AddEditEvaluationModal
              openModal={openModal}
              editMode={editMode}
              selectedEvaluation={selectedEvaluation}
              closeAddEditEvaluationModal={this.closeAddEditEvaluationModal}
              saveEvaluation={this.saveEvaluation}
            />
          )}
        </div>
      </JssProvider>
    );
  }
}

export default connectProfile()(EvaluationList);
