import React, {Component} from 'react';
import './Dct.scss';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import AddIcon from '@material-ui/icons/Add';
import ContentBlockEditor from './contentTabs/ContentBlockEditor';
import Card from '@material-ui/core/Card';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import Header from "../../../../layout/Header"
import moment from "moment"
import {Loader} from "../../../shared/loader"
import ConversationService from "../../../../services/ConversationService"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import AddCBModal from "../modal/AddCBModal"
import CalculatorIconDark from "../../../../assets/images/calculator-dark.svg"
import CalculatorIconGrey from "../../../../assets/images/calculator-grey.svg"
import AddEditDctModal from "../modal/AddEditDctModal"
import {getContentBlockHeaderText} from "../../../../utilities/CommonUtils"
import {BUILDER_CONSTANTS} from "../../../../constants/CommonConstants"
import {HoverableReference} from "../../../shared/HoverableReference"
import DCTScoringModal from "../modal/DCTScoringModal"
import {PublishConfirmationModal} from "../modal/PublishConfirmationModal"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

const EMPTY_DCT_STATE = {
  name: '',
  version: '',
}

class EditDctContentBlocks extends Component {

  constructor (props) {
    super(props);
    this.state = {
      unsaved: false,
      dct: this.props.location.state?.dct || EMPTY_DCT_STATE,
      scoring: undefined, // Undefined Value set on purpose. Don't change
      contentBlocks: [],
      profileElements:[],
      selectedBlock:null,
      isLoading:true,
      showAddCBModal:false,
      editDctModal:false,
      secondary:false,
      showScoreModal:false,
      publishConfirmationModal:false,
      automationRippleCount:0,
  }
    ;

  }

  componentDidMount = async () => {
    await this.getDCTDetails();
    await this.getProfileElements();
  }

  componentDidUpdate (prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if (prevProps.match.params.dctId !== this.props.match.params.dctId) {
      this.setState({
        isLoading: true
      });
      this.getDCTDetails();
    }
  }

  closePublishConfirmModal = () => {
    this.setState({
      publishConfirmationModal: false
    });
  };

  getDCTDetails = async () => {
    const dctId = this.props.match.params.dctId;
    const response = await ConversationService.getDCTDetails(dctId);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
    } else {
      this.setState({
        dct: response.dct,
        scoring: response.scoring || null
      }, this.getContentBlocks);
    }
  };

  /**
   * @function getProfileElements
   * @description This method is used to get all profile elements.
   */
  getProfileElements = async () => {
    const response = await ConversationService.getProfileElementsList();
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({isLoading: false});
    } else {
      this.setState({
        profileElements: response.profileElementList,
        isLoading: false
      });
    }

  }

  switchBlock = (contentBlock) => {
    if (this.state.selectedBlock && this.state.selectedBlock.cbId === contentBlock.cbId) {
      return;
    }
    if (this.state.unsaved) {
      AlertUtil.showError("Please save the selected block before switching");
    } else {
      this.setState({
        selectedBlock: contentBlock
      })
    }
  }

  /**
   * @function getContentBlocks
   * @description This method is used to get content block by dctID.
   */
  getContentBlocks = async () => {
    const dctId = this.props.match.params.dctId;
    const blocksResponse = await ConversationService.getContentblocksByDct(dctId);
    if (blocksResponse.errors) {
      AlertUtil.showError(blocksResponse.errors[0].endUserMessage);
    } else {
      this.setState({
        contentBlocks: blocksResponse.contentBlocks,
        // dct : blocksResponse.dct,
        editLocked: blocksResponse.locked,
        isLoading: false
      });
    }

  };

  /**
   * @function addCB
   * @description This method is used to add new content block.
   * @params request
   */
  addCB = async (request) => {
    this.closeAddCBModal();
    this.setState({
      isLoading: true
    });
    request.dctId = this.props.match.params.dctId;
    const response = await ConversationService.addContentBlock(request);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false
      })
    } else {
      const {type, text, referenceId} = request;
      const cb = {
        cbId: response.cbId,
        basicInfo: {type, text, referenceId},
        routingOption: "NEXT"
      };
      this.setState({
        isLoading: false,
        contentBlocks: [...this.state.contentBlocks, cb]
      }, () => {
        this.switchBlock(cb);
      });

    }
  };

  closeAddCBModal = () => {
    this.setState({
      showAddCBModal: false
    });
  };

  /**
   * @function saveContentBlock
   * @description This method is used to save/update content block.
   * @params cbId , contentBlock
   */
  saveContentBlock = async (cbId, contentBlock) => {
    this.setState({
      isLoading: true,
      selectedBlock: null
    });
    const response = await ConversationService.saveContentBlock(cbId, contentBlock);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false,
        selectedBlock: {...contentBlock, cbId}
      });
    } else {
      AlertUtil.showSuccess(response.message);
      await this.getContentBlocks();
      this.setState({
        selectedBlock: this.state.contentBlocks.filter(cb => cb.cbId === cbId)[0]
      });
    }
  };

  blockHasScoring = (contentBlock) => {
    return contentBlock && contentBlock.basicInfo && contentBlock.basicInfo.choices
      && contentBlock.basicInfo.choices.filter(choice => choice.score && parseInt(choice.score) > 0).length > 0
  };

  closeEditDctModal = () => {
    this.setState({
      editDctModal: false
    });
  };

  closeScoringModal = () => {
    this.setState({
      showScoreModal: false
    });
  };

  /**
   * @function saveDct
   * @description This method is used to save dct.
   * @params Dct Request
   */
  saveDct = async (request) => {
    this.setState({
      isLoading: true,
      selectedBlock: null
    });
    const dctId = this.props.match.params.dctId;
    const response = await ConversationService.updateDCT(dctId, request);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({isLoading: false});
    } else {
      AlertUtil.showSuccess("DCT Updated Successfully");
      this.setState({
        isLoading: false,
        dct: {...request, lastUpdated: new Date().toISOString()},
        editDctModal: false
      });
      await this.getContentBlocks();
    }

  };

  /**
   * @function copyBlock
   * @description This method is used for copy content block.
   * @params cbId
   */
  copyBlock = async (cbId) => {
    const selectedBlock = this.state.selectedBlock;
    this.setState({
      isLoading: true,
      selectedBlock: null
    });
    const response = await ConversationService.copyContentBlock(cbId);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false,
        selectedBlock
      });
    } else {
      await this.getContentBlocks();
      AlertUtil.showSuccess("Content Block replicated successfully inside DCT");
      this.switchBlock(selectedBlock);
    }
  };

  /**
   * @function deleteBlock
   * @description This method is used to delete content block.
   * @params cbId
   */
  deleteBlock = async (cbId) => {
    const selectedBlock = this.state.selectedBlock;
    this.setState({
      isLoading: true,
      selectedBlock: null
    });
    const response = await ConversationService.deleteContentBlock(cbId);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false,
        selectedBlock
      });
    } else {
      await this.getContentBlocks();
      AlertUtil.showSuccess("Content Block Deleted from DCT");
    }
  };

  /**
   * @function reorder
   * @description This method is used to reorder content blocks list.
   * @params list , startIndex , endIndex
   */
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  /**
   * @function onDragEnd
   * @description This method provides drag/drop functionality.
   * @params DragDropContextDetail
   */
  onDragEnd = async (DragDropContextDetail) => {
    if (!DragDropContextDetail.destination) {
      return;
    }
    const {source, destination} = DragDropContextDetail;
    if (source.index === destination.index) {
      return;
    }

    let {contentBlocks, dct} = this.state;
    contentBlocks = this.reorder(
      contentBlocks,
      source.index,
      destination.index
    );
    contentBlocks = contentBlocks.map((contentBlock => contentBlock.cbId));
    const dctRequest = {
      ...dct, contentBlocks, reorder: true
    }
    await this.saveDct(dctRequest);
  }

  viewReport = (dct) => {
    this.props.history.push(`/admin/conversation-builder/dct/report/${this.props.match.params.dctId}`, {dct: dct});
  }

  getRoutingText = (contentBlock) => {
    if (contentBlock.routing) {
      return BUILDER_CONSTANTS.RoutingOptions.SPECIFIC;
    } else if (contentBlock.routingOption === 'NEXT') {
      return BUILDER_CONSTANTS.RoutingOptions.NEXT;
    } else {
      return BUILDER_CONSTANTS.RoutingOptions.END;
    }
  }

  /**
   * @function editDct
   * @description This method is used to navigate to edit dct screen.
   */
  editDct = (dctId) => {
    this.props.history.push(`/admin/conversation-builder/dct/edit/${dctId}`);
  }

  /**
   * @function upgradeDctVersion
   * @description This method is upgrade DCT version.
   */
  upgradeDctVersion = async () => {
    this.setState({isLoading: true, selectedBlock: null});
    try {
      const dctId = this.props.match.params.dctId;
      const response = await ConversationService.upgradeDctVersion(dctId);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          isLoading: false
        });
      } else {
        AlertUtil.showSuccess("DCT version upgraded Successfully");
        this.setState({isLoading: false});
        this.editDct(response.id);
        this.getContentBlocks();
      }
    } catch (e) {
      console.log(e);
      AlertUtil.showError("Something went wrong! Please try again");
      this.setState({isLoading: false});
    }
  }

  /**
   * @function getVersionText
   * @description This method returns version text.
   */
  getVersionText = (version) => {
    return "v " + (version ? version : "0") + ".0";
  }

  saveScoring = async (scoring) => {
    this.setState({
      showScoreModal: false,
      isLoading: true
    });
    const dctId = this.props.match.params.dctId;
    const response = await ConversationService.updateDCTScoring(scoring, dctId);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false
      });
    } else {
      AlertUtil.showSuccess("Scoring Updated");
      this.setState({
        isLoading: false
      });
      this.getDCTDetails();
    }
  };

  editNextDraft = (dct) => {
    this.props.history.push(`/admin/conversation-builder/dct/edit/${dct.nextDraftId}`);
  };

  publishDCT = async () => {
    this.setState({isLoading: true});
    const dctId = this.props.match.params.dctId;
    const response = await ConversationService.publishDCT(dctId);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({isLoading: false});
    } else {
      AlertUtil.showSuccess("DCT Published successfully")
      await this.getContentBlocks();
      await this.getDCTDetails();
    }
  };

  checkRipplesForDraft = async () => {
    this.setState({isLoading: true});
    const dctId = this.props.match.params.dctId;
    const response = await ConversationService.getDCTRipplesCount(dctId);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({isLoading: false});
    } else {
      this.setState({
        isLoading: false,
        publishConfirmationModal: true,
        automationRippleCount: response.automationsCount,
      });
    }
  };

  render () {
    const {
      isLoading, dct, contentBlocks, selectedBlock, profileElements, showAddCBModal, editDctModal, editLocked
    } = this.state;
    const isDraft = dct.status === 'DRAFT';
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <div className="main-body-c">
            <Header
              bradCrumbs={[{title: "DCT's", link: "/admin/conversation-builder/dct/list"}]}
              tile={dct.name ? dct.name : 'N/A'}
            />

            <div className="dct-detail-page-header-main"  style={{height: '180px'}}>
              {editLocked && !dct.nextDraftId && (
                <div className="main-banner">
                  <p>This dct is locked for editing because it is actively being used by members. Please upgrade its
                    version to make changes</p>
                </div>
              )}
              {editLocked && dct.nextDraftId && (
                <div className="main-banner">
                  <p>This dct is locked and Draft for the next version is already available. Click on Edit Next Version
                    Draft to make changes</p>
                </div>
              )}
              {isDraft && (
                <div className="draft-banner">
                  <p>This dct is in draft state. Click on Publish to make it active for members.</p>
                </div>
              )}
              <div className="detail-page-header"  style={{height: '140px'}}>
                <div className="dct-details-top-content">
                  <div className="dct-details-top-content-block">
                    <h3
                      className="dct-details-top-content-main-heading">{dct.name ? dct.name : 'N/A'}</h3>
                    <p
                      className="dct-details-top-content-main-text">{this.getVersionText(dct.version)}</p>
                  </div>
                  <div className="dct-details-top-content-block">
                    <h3 className="dct-details-top-content-heading">Last Updated</h3>
                    <p
                      className="dct-details-top-content-text">{dct.lastUpdated ? moment(dct.lastUpdated).format("DD/MM/YYYY") : 'N/A'}</p>
                  </div>
                  <div className="dct-details-top-content-block">
                    <h3 className="dct-details-top-content-heading">Scorable</h3>
                    <p className="dct-details-top-content-text">{dct.scorable ? 'Yes' : 'No'}</p>
                  </div>
                </div>
                <div className="dct-Details-top-actions">
                  {
                    !isDraft && (
                      <Button className="custom-btn" variant="contained" color="primary"
                              onClick={() => {this.viewReport(dct)}}>
                        View Report
                      </Button>
                    )
                  }

                  {
                    dct.scorable && !isDraft && this.state.scoring !== undefined && (
                      <Button onClick={() => {this.setState({showScoreModal: true})}}
                              className="custom-btn-outlined-with-white-bg" variant="contained" color="primary">
                        Edit Scoring
                      </Button>
                    )
                  }
                  {
                    !isDraft && (
                      <Button onClick={() => {this.setState({editDctModal: true})}}
                              className="custom-btn-outlined-with-white-bg" variant="contained" color="primary">
                        Edit Details
                      </Button>
                    )
                  }


                  {editLocked && !dct.nextDraftId && (
                    <Button onClick={() => this.upgradeDctVersion()}
                            className="custom-btn-outlined-with-white-bg" variant="contained" color="primary">
                      Upgrade Version
                    </Button>
                  )}
                  {editLocked && dct.nextDraftId && (
                    <Button onClick={() => this.editNextDraft(dct)}
                            className="custom-btn-outlined-with-white-bg" variant="contained" color="primary">
                      Edit Next Version Draft
                    </Button>
                  )}
                  {isDraft && (
                    <Button onClick={() => {
                      this.checkRipplesForDraft()
                    }}
                            className="custom-btn-outlined-with-white-bg" variant="contained" color="primary">
                      Publish
                    </Button>
                  )}

                </div>
              </div>
            </div>

            {
              isLoading ? <Loader/> : <div className="content-main">

                <Card style={{width: "100%"}} className='content-block-main' variant="outlined">
                  <div style={{width: "40%"}} className="content-block-8blocks">
                    <div className="content-block-8blocks-header">
                      <h3>{contentBlocks.length} Content
                        Block{contentBlocks.length > 1 ? 's' : ''}</h3>
                      <button className="icon-wrapper"
                              disabled={editLocked}
                              onClick={() => {
                                this.setState({
                                  showAddCBModal: true
                                })
                              }}>
                        <AddIcon style={{color: "#fff", weight: '400'}}/>
                      </button>
                    </div>

                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable droppableId="characters">
                        {(provided) => (
                          <div className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                            {this.state.contentBlocks.map((contentBlock, index) => {
                              return (
                                <Draggable isDragDisabled={editLocked} key={contentBlock.cbId}
                                           draggableId={contentBlock.cbId} index={index}>
                                  {(provided) => (
                                    <div
                                      onClick={() => {
                                        this.switchBlock(contentBlock);
                                      }}
                                      ref={provided.innerRef} {...provided.draggableProps} >
                                      <div className="content-block-8blocks-item"
                                           style={{backgroundColor: selectedBlock && selectedBlock.cbId === contentBlock.cbId ? '#F1F9FF' : 'inherit'}}>
                                        <div className="content-block-8blocks-item-icon" {...provided.dragHandleProps}>
                                          <DragIndicatorIcon style={{color: "#0091F1", weight: '400'}}/>
                                        </div>
                                        <div className="content-block-8blocks-item-content">
                                          <div className="content-block-8blocks-item-content-text">
                                            <h4>{getContentBlockHeaderText(contentBlock)}</h4>
                                            <HoverableReference contentBlock={contentBlock}
                                                                allBlocks={this.state.contentBlocks}/>
                                          </div>
                                          <p>{BUILDER_CONSTANTS.BlockTypeNames[contentBlock.basicInfo?.type]}</p>
                                          <div className="content-block-8blocks-item-content-footer">
                                            <h5>Routing: {this.getRoutingText(contentBlock)}</h5>
                                            <div className="icons">
                                              <InsertLinkIcon className="link-icon block-icon"
                                                              style={{color: !contentBlock.basicInfo.variableMapping ? '#CAD1E0' : 'black'}}/>
                                              <VisibilityIcon className="block-icon"
                                                              style={{color: !contentBlock.rawDisplayLogic && !contentBlock.displayLogics ? '#CAD1E0' : 'black'}}/>
                                              <img className="block-icon"
                                                   src={this.blockHasScoring(contentBlock) ? CalculatorIconDark : CalculatorIconGrey}
                                                   alt="calculatoricon"/>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    {
                      !editLocked && contentBlocks.length>1 && (
                        <div style={{margin: '20px', display: 'flex',justifyContent: 'center'}}>
                          <Button className="custom-btn" variant="contained" color="primary"
                                  disabled={editLocked}
                                  onClick={() => {
                                    this.setState({showAddCBModal: true})
                                  }}>
                            Add content block
                          </Button>
                        </div>
                      )
                    }
                  </div>

                  <div className="ContentBlock-Editor-Wrapper">
                    <ContentBlockEditor
                      dctEditor
                      copyBlock={this.copyBlock}
                      deleteBlock={this.deleteBlock}
                      selectedBlock={selectedBlock}
                      allContentBlocks={contentBlocks}
                      profileElements={profileElements
                        .filter(element=>element.profileElementInfo && element.profileElementInfo.key && element.profileElementInfo.key.trim()!=='')
                        .sort((a, b) => a.profileElementInfo.key.trim().localeCompare(b.profileElementInfo.key.trim()))}
                      onSave={this.saveContentBlock}
                      saveChoiceTemplate={this.saveChoiceTemplate}
                      editLocked={editLocked}
                    />
                  </div>
                </Card>
                <AddCBModal
                  dctEditor
                  openModal={showAddCBModal}
                  closeModal={this.closeAddCBModal}
                  refToStart={contentBlocks.length + 1}
                  addCB={this.addCB}
                  allContentBlocks={contentBlocks}
                  history={this.props.history}
                  isEvaluation={this.props.location.state?.isEvaluation}
                />

                <AddEditDctModal
                  openModal={editDctModal}
                  closeModal={this.closeEditDctModal}
                  dct={dct}
                  saveDct={this.saveDct}
                />

                {
                  this.state.showScoreModal && this.state.scoring !== undefined &&
                  <DCTScoringModal
                    openModal={this.state.showScoreModal}
                    closeModal={this.closeScoringModal}
                    profileElements={this.state.profileElements}
                    scoring={this.state.scoring}
                    saveScoring={this.saveScoring}
                  />
                }
                <PublishConfirmationModal
                  openModal={this.state.publishConfirmationModal}
                  type={'dct'}
                  automationsCount={this.state.automationRippleCount}
                  dctCount={0}
                  closeModal={this.closePublishConfirmModal}
                  proceed={this.publishDCT}
                />


              </div>
            }

          </div>
        </div>

      </JssProvider>
    );
  }
}

export default EditDctContentBlocks;
