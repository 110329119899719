import * as contentful from 'contentful/dist/contentful.browser.min';
import AppConfig from '../../config/AppConfig';
import {AlertUtil} from '../../utilities/AlertUtil';

const SPACE_ID = AppConfig.config.external['contentful.spaceId'];
const ACCESS_TOKEN = AppConfig.config.external['contentful.accessToken'];
const HOST = 'cdn.contentful.com';
export const ContentfulClient = contentful.createClient({
    space: SPACE_ID,
    accessToken: ACCESS_TOKEN,
    host: HOST,
});

const queryMethod = ContentfulClient.getEntries;
ContentfulClient.getEntries = function (query) {
    return queryMethod.apply(this, [query]).catch(e => {
        AlertUtil.showErrorMessage("Unable to fetch data from contentful");
        console.log(e);
        return {
            total: 0,
            items: [],
        };
    });

};
