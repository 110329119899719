import React, {Component} from 'react';
import Header from "../../../../../layout/Header"
import TopSectionComponent from "../../../../shared/top-section/TopSectionComponent"
import './appointment-reporting.scss';
import {Loader} from "../../../../shared/loader"
import Paper from "@material-ui/core/Paper"
import DataTable from "react-data-table-component"
import moment from "moment"
import {AlertUtil} from "../../../../../utilities/AlertUtil"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import {connectProfile} from "../../../../../redux/modules/profile/connectProfile"
import ScheduleService from "../../../../../services/ScheduleService"
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import format from "date-fns/format";
import Button from "@material-ui/core/Button"
import {CSVLink} from "react-csv"

class AppointmentReporting extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: true,
      appointments: [],
      insuranceDataDownload: [],
      totalRecords: 0,
      dataLoading: true,
      reset: false,
      currentPage: 0,
      pageSize: 10,
      sortBy: [],
      orderBy: '',
      payeeSelected: 'All',
      startDate: new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)),
      endDate: new Date(),
      payee: ["All",
        "Insurance",
        "RecurringSubscription",
        "Wallet/Stripe"],
      columns: [
        {
          name: "Patient First Name",
          selector: row => row.patientFirstName || "NA",
          sortable: true
        },
        {
          name: "Patient Last Name",
          selector: row => row.patientLastName || "NA",
          sortable: true
        },
        {
          name: "Provider Name",
          selector: row => row.providerName || "NA",
          sortable: true
        },
        {
          name: "Service Type",
          selector: row => row.serviceType || "NA",
          sortable: false
        },
        {
          name: "Service Name",
          selector: row => row.serviceName || "NA",
          sortable: true,
        },
        {
          name: "Payee Type",
          selector: row => row.payeeType || "NA",
          sortable: true,
        },
        {
          name: "Insurance Provider",
          selector: row => row.insuranceProvider || "NA",
          sortable: true,
        },
        {
          name: "Cpt Code",
          selector: row => row.cptCode || "NA",
          sortable: true,
        },
        {
          name: "Amount Paid",
          selector: row => row.amountPaid || "NA",
          sortable: true,
        },
        {
          name: "Service Cost",
          selector: row => row.serviceCost || "NA",
          sortable: true,
        },
        {
          format: row => row.appointmentTime ? moment(row.appointmentTime).format("DD/MM/YYYY  hh:mm a") : 'N/A',
          name: "Appointment Time",
          selector: row => row.appointmentTime,
          sortable: true,
        }
      ]
    }
  }

  handleChange (date, type) {
    if (type === "START") {
      this.setState({
        startDate: date
      })
    } else {
      this.setState({
        endDate: date
      })
    }
    this.fetchAppointments()
  }

  componentDidMount () {
    this.fetchAppointments();
  }

  fetchAppointments = async () => {

    this.setState({
      dataLoading: true
    });
    const {
      currentPage, pageSize,
      payeeSelected, startDate, endDate
    } = this.state;

    try {
      const request = {
        payee: payeeSelected,
        startDate: format(startDate, "yyyy-MM-dd"),
        endDate: format(endDate, "yyyy-MM-dd"),
        download: false,
        pageNumber: currentPage,
        pageSize: pageSize,
        orderBy: "ASC"
      };
      const response = await ScheduleService.fetchAppointmentsReports(request);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          isLoading: false,
          dataLoading: false
        });
      } else {
        console.log(response)
        setTimeout(() => {
          const insuranceDataDownload = response?.appointmentRecord?.map(item => {
            //We need to print these keys as an excel file for proper heading that why we can't use case camel.
            return {
              PATIENT_FIRST_NAME: item.patientFirstName,
              PATIENT_LAST_NAME: item.patientLastName,
              PROVIDER_NAME: item.providerName,
              SERVICE_TYPE: item.serviceType,
              SERVICE_NAME: item.serviceName,
              SERVICE_DURATION: item.serviceDuration,
              PAYEE_TYPE: item.payeeType,
              INSURANCE_PROVIDER: item.insuranceProvider,
              AMOUNT_PAID: item.amountPaid,
              SERVICE_COST: item.serviceCost,
              CPT_CODE: item.cptCode,
            };
          });
          this.setState({
            appointments: response?.appointmentRecord,
            insuranceDataDownload: insuranceDataDownload,
            totalPages: response?.totalPages === null ? 0 : response?.totalPages,
            totalRecords: response?.totalRecords === null ? 0 : response?.totalRecords,
            isLoading: false,
            dataLoading: false
          });
          console.log(this.state)
        }, 2000);
      }
    } catch (e) {
      console.log(e);
      AlertUtil.showError("Something went wrong!");
      this.setState({isLoading: false, dataLoading: false});
    }

  };

  render () {
    const {
      reset, columns, appointments, totalRecords,
      dataLoading, payee, payeeSelected, startDate, endDate
    } = this.state;

    return (
      <div className="main-container-appointments">
        <Header/>
        <div>
          <TopSectionComponent title={'Appointments'}/>
        </div>
        <div className="main-body-appointments">
          {
            this.state.isLoading ? <Loader/> : (
              <>
                <div className="table-mid">
                  <Paper component="form"
                         className={'shadow-none radius-none m-b-15 d-flex align-items-center justify-content-end flex-direction-row'}>

                    <div className="dropdown-select m-l-15 ">
                      <label htmlFor="fromDateLabel">From Date</label>
                      <DatePicker
                        placeholder='Enter start date'
                        selected={startDate}
                        maxDate={new Date()}
                        onChange={(value) => this.handleChange(value, "START")}
                        dateFormat="yyyy/MM/dd"
                        name="startDate"
                      />
                    </div>
                    <div className="dropdown-select m-l-15 ">
                      <label htmlFor="toDateLabel">To Date</label>
                      <DatePicker
                        placeholder='Enter end date'
                        selected={endDate}
                        maxDate={new Date()}
                        onChange={(value) => this.handleChange(value, "END")}
                        dateFormat="yyyy/MM/dd"
                        name="endDate"
                      />
                    </div>
                    <div className="dropdown-select m-l-15 ">
                      <label htmlFor="payee" style={{marginRight: 16}}>Payee</label>
                      <Select
                        id="payeeFilter"
                        displayEmpty
                        className={'payee-filter'}
                        value={payeeSelected}
                        onChange={({target}) => {
                          this.setState({
                            payeeSelected: target.value,
                          }, this.fetchAppointments)
                        }}
                      >
                        {
                          payee?.map(payeeVal =>
                            <MenuItem key={payeeVal} value={payeeVal}>{payeeVal}</MenuItem>)
                        }
                      </Select>
                    </div>
                    <div className="dropdown-select m-l-15 ">
                      <Button variant="contained" color="primary">
                        <CSVLink className="Download-btn" filename={"Appointment-Insurance.csv"}
                                 data={this.state?.insuranceDataDownload===undefined?[]:this.state.insuranceDataDownload}>Download</CSVLink>
                      </Button>
                    </div>
                  </Paper>
                  <Paper className="table-component-main shadow-none radius-none" elevation={2}>
                    <DataTable
                      columns={columns}
                      data={appointments===null?[]:appointments}
                      progressPending={dataLoading}
                      persistTableHead
                      keyField={'appointmentTime'}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRecords===null?0:totalRecords}
                      paginationDefaultPage={1}
                      paginationResetDefaultPage={reset}
                      onChangeRowsPerPage={this.handlePerRowsChange}
                      onChangePage={this.handlePageChange}
                      // sortServer={true}
                      // onSort={this.onSort}
                    />
                  </Paper>
                </div>
              </>
            )
          }
        </div>
      </div>
    )

  }

}

export default connectProfile()(AppointmentReporting)
