import React, {Component} from 'react';
import './DomainElementsList.css';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import {DebounceInput} from "react-debounce-input"
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Header from "../../../../../layout/Header"
import ConversationService from "../../../../../services/ConversationService"
import {AlertUtil} from "../../../../../utilities/AlertUtil"
import AddEditDomainElementModal from "../../modal/AddEditDomainElementModal"
import DataTable from "react-data-table-component";
import {InputBase} from "@material-ui/core"
import {Loader} from "../../../../shared/loader"
import Paper from '@material-ui/core/Paper';
import VisibilityIcon from "@material-ui/icons/Visibility"
import Delete from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import DeleteModal from "../../modal/DeleteModal"
import ReactTooltip from "react-tooltip";
import {BUILDER_CONSTANTS} from "../../../../../constants/CommonConstants"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class DomainElementsList extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isLoading: true,
      dataLoading: false,
      dataDomainElementsList: [],
      openAddEditModal: false,
      openDeleteModal: false,
      openToolTip: false,
      profileElements: [],
      searchQuery: '',
      pageSize: 10,
      currentPage: 1,
      orderBy: '',
      sortBy: [],
      viewMode : false,
      lookupMap: {},
      columns: [
        {
          format: row => row?.name ? row?.name : 'N/A',
          name: "Name",
          selector: row => row?.name || "",
          sortable: true
        },

        {
          format: row => row?.parent ? this.getDomainTypeName(row.parent) : 'N/A',
          name: "Domain Type",
          selector: row => row?.parent || "",
          sortable: true
        },
        {
          format: row => row.metaData?.metaDataValues?.importanceLevelsValues ? this.getObjectSize(row.metaData?.metaDataValues?.importanceLevelsValues) + ` key : value pairs` : 'N/A',
          name: "Importance Mapping",
          selector: row => row.metaData?.metaDataValues?.importanceLevelsValues || "",
          sortable: false
        },

        {
          format: row => (row.metaData?.metaDataValues?.rxDrugInfo ? "true" : "false"),
          name: "RX Drugs Info",
          selector: row => row?.metaData?.metaDataValues?.rxDrugInfo || "",
          sortable: false,
          cell: (row) => {
            const hasRx = row.metaData?.metaDataValues?.rxDrugInfo && Object.keys(row.metaData?.metaDataValues?.rxDrugInfo).length>0;
            return (
              <div>
                <span data-tip={row?.metaData?.metaDataValues?.rxDrugInfo ? "RxDrugInfo" : ""}
                      data-for={row?.Id}>{hasRx ? "true" : "false"}</span>
                {
                  hasRx && <ReactTooltip
                    id={row?.Id}
                    effect="solid"
                    place="bottom"
                    getContent={() => this.renderToolTip(row?.metaData?.metaDataValues?.rxDrugInfo)}/>
                }

              </div>
            )
          }
        },
        {
          format: row => (row.metaData?.metaDataValues?.relatedToMapping ? this.getObjectSize(row.metaData?.metaDataValues?.relatedToMapping) + ` key : value pairs` : 'N/A'),
          name: "Related To Mapping",
          selector: row => row?.metaData?.metaDataValues?.relatedToMapping || "",
          sortable: false
        },
        {
          format: row => (row.metaData.metaDataSpec?.requireInterferenceWithLifeInfo && row.metaData?.metaDataValues?.relatedToInterferenceWithLife ? this.getInterferenceKeyValueSize(row.metaData?.metaDataValues?.relatedToInterferenceWithLife) + ` key : value pairs` : 'N/A'),
          name: "Interference Mapping",
          selector: row => row?.metaData?.metaDataValues?.relatedToInterferenceWithLife || "",
          sortable: false
        },
        {
          name: "Actions",
          cell: row => <div className="buttons-Wrapper">
            <Button className="edit-OutLined-btn" variant="outlined"
                    onClick={() => {this.openAddEditDomainElementModal(false, row,true)}}>
              <VisibilityIcon style={{color: "#0091F1"}} color="action"/>
            </Button>
            <Button className="edit-OutLined-btn" variant="outlined"
                    onClick={() => {this.openAddEditDomainElementModal(true, row,false)}}>
              <EditIcon style={{color: "#0091F1"}} color="action"/>
            </Button>
            <Button className="edit-OutLined-btn" variant="outlined"
                    onClick={() => this.setState({domainElement: row, openDeleteModal: true})}>
              <Delete style={{color: "#0091F1"}} color="action"/>
            </Button>
          </div>
        }
      ]

    }
  }

  getInterferenceKeyValueSize = (relatedToInterferenceWithLife)=>{
    if(!relatedToInterferenceWithLife.relatedProfileElements) {
      return 0;
    }
    const mappingSize = this.getObjectSize(relatedToInterferenceWithLife.relatedProfileElements);
    if(mappingSize>0) {
      let size = 0;
      const elementKey = Object.keys(relatedToInterferenceWithLife.relatedProfileElements)[0];
      const values = relatedToInterferenceWithLife.relatedProfileElements[elementKey];
      if(values) {
        if(!!values.yes) {
          size++;
        }
        if(!!values.no) {
          size++;
        }
      }
      return size;
    }
    return 0;
  };

  /**
   * @function getDomainTypeName
   * @description This method is used to get domain type Name.
   */
  getDomainTypeName = (domainTypeId) => {
    let {dataDomainTypes} = this.state;
    if (dataDomainTypes && dataDomainTypes.length > 0) {
      dataDomainTypes = dataDomainTypes.filter(dataDomainType => dataDomainType?.Id === domainTypeId);
      if (dataDomainTypes && dataDomainTypes.length > 0) {
        return dataDomainTypes[0].name;
      }
    }
  }

  /**
   * @function renderToolTip
   * @description This method is used to render tooltip data.
   */
  renderToolTip = (selectedToolTipObject) => {
    if (selectedToolTipObject != null) {
      return (
        Object.keys(selectedToolTipObject).map(key => {
          return (
            <div key={key}>
              <p>{this.getFieldText(key)} : </p>
              <p>{selectedToolTipObject[key] || "N/A"}</p>
            </div>
          )
        })
      )
    }
  }

  /**
   * @function getFieldText
   * @description This method is used get field Text.
   */
  getFieldText = (type) => {
    switch (type) {
      case "doseFrequency" :
        return "Dose Frequency";
      case "doseUnit" :
        return "Dose Unit";
      case "highEndDose" :
        return "High End Dose";
      case "highEndSupply" :
        return "High End Supply";
      case "lowEndDose" :
        return "Low End Dose";
      case "lowEndSupply" :
        return "Low End Supply";
      case "medicationType" :
        return "Medication Type";
      case "refillable" :
        return "Refillable";
      case "relatedEducationContent" :
        return "Related Education Content";
      case "supplyUnit" :
        return "Supply Unit";
      default:
        return "";
    }

  }

  /**
   * @function getObjectSize
   * @description This method is used get No of key/value pairs.
   */
  getObjectSize = (obj) => {
    let size = 0, key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) {
        size++;
      }
    }
    return size;
  };

  componentDidMount = async () => {
    await this.getDomainTypesList();
    this.getDataDomainElementsList();
    this.getProfileElements();
    this.getDomainLookup();
    this.getTags();
  }
  /**
   * @function handlePageChange
   * @description This method is used to handle pagination.
   */
  handlePageChange = page => {
    this.setState({
      currentPage: page,
    }, this.getDataDomainElementsList);
  };
  /**
   * @function getDataDomainElementsList
   * @description This method is used to get all domain elements from the system .
   */
  getDataDomainElementsList = async () => {
    this.setState({dataLoading: true});
    try {
      const {searchQuery, currentPage, pageSize, orderBy, sortBy} = this.state;
      const response = await ConversationService.getDataDomainElements(searchQuery, currentPage - 1,
        pageSize, orderBy, sortBy);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          isLoading: false,
          dataLoading: false
        });
      } else {
        this.setState({
          dataDomainElementsList: response.records || [],
          totalPages: response.totalPages,
          totalRecords: response.totalRecords,
          isLoading: false,
          dataLoading: false
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        isLoading: false,
        dataLoading: false
      });
    }
  }

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState({
      pageSize: newPerPage,
    }, this.getDataDomainElementsList)
  };
  /**
   * @function openAddEditDomainElementModal
   * @description This method is used to open data domain element modal .
   */
  openAddEditDomainElementModal = (editMode, domainElement,viewMode) => {
    this.setState({editMode,viewMode, domainElement, domainElementId: domainElement?.Id, openAddEditModal: true});
  }
  /**
   * @function closeAddEditDomainTypeModal
   * @description This method is used to close data domain element modal.
   */
  closeAddEditDomainElementModal = () => {
    this.setState({openAddEditModal: false});
  }
  /**
   * @function saveDomainElement
   * @description This method is used to save domain element in the system.
   */
  saveDomainElement = async (domainElementRequest, editMode) => {
    this.setState({dataLoading: true});
    try {
      let domainElementCall = ConversationService.addDomainElement;
      if (editMode) {
        domainElementCall = ConversationService.updateDomainElement;
      }
      const response = await domainElementCall(domainElementRequest, this.state.domainElementId);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          openAddEditModal: false,
          dataLoading: false
        });
      } else {
        AlertUtil.showSuccess(editMode ? "Domain Element updated successfully" : "Domain Element added successfully");
        this.setState({
          openAddEditModal: false,
          dataLoading: false
        });
        await this.getDataDomainElementsList();
      }
    } catch (e) {
      console.log(e);
      this.setState({
        openAddEditModal: false,
        dataLoading: false
      });
    }
  }

  /**
   * @function deleteDomainElement
   * @description This method is used to delete domain element.
   */
  deleteDomainElement = async (domainElement) => {
    this.setState({dataLoading: true});
    try {
      const response = await ConversationService.deleteDomainElement(domainElement.Id);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          dataLoading: false,
          openDeleteModal: false
        });
      } else {
        AlertUtil.showSuccess("Domain element deleted successfully");
        this.setState({
          dataLoading: false,
          openDeleteModal: false
        });
        await this.getDataDomainElementsList();
      }
    } catch (e) {
      console.log(e);
      this.setState({
        dataLoading: false,
        openDeleteModal: false
      });
    }
  }

  /**
   * @function closeDomainElementDeleteModal
   * @description This method is used to close data domain element modal.
   */
  closeDomainElementDeleteModal = () => {
    this.setState({openDeleteModal: false});
  }

  /**
   * @function getProfileElements
   * @description This method is used to get all profile Elements from the system.
   */
  getProfileElements = async () => {
    try {
      const response = await ConversationService.getProfileElementsList();
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        this.setState({
          profileElements: response.profileElementList.filter(profileElement => profileElement?.profileElementInfo.values && profileElement?.profileElementInfo.values.length > 0) || []
        });
      }
    } catch (e) {
      console.log(e);
    }

  }
  /**
   * @function getDomainLookup
   * @description This method is used to get all lookup keys for the dropdowns.
   */
  getDomainLookup = async () => {
    try {
      const response = await ConversationService.getDomainLookup();
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        this.setState({
          lookupMap: response.lookupMap
        });
      }
    } catch (e) {
      console.log(e);
    }

  }

  /**
   * @function getDomainTypesList
   * @description This method is used to get all domain types from the system.
   */
  getDomainTypesList = async () => {
    this.setState({isLoading: true});
    try {
      const response = await ConversationService.getDataDomainTypes();
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
      } else {
        this.setState({
          dataDomainTypes: response.records || [],
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * @function getTags
   * @description This method is used to get all Tags.
   */

  getTags = async () => {
    const response = await ConversationService.getTagsList();
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
    } else {
      this.setState({
        tags: response.tagList ? response.tagList : []
      });
    }
  }

  /**
   * @function getFieldText
   * @description This method is used to get Field text.
   */
  getFieldText = (fieldName) => {
    switch (fieldName) {
      case 'Name' :
        return "name"
      case 'Domain Type' :
        return "parent"
      default :
        return fieldName
    }
  }

  /**
   * @function onSort
   * @description This method is used to sort field values from BE .
   */
  onSort = async (column, sortDirection) => {
    let orderBy = BUILDER_CONSTANTS.SORT_DIRECTIONS[sortDirection];
    const sortBy = this.getFieldText(column.name);
    orderBy = sortBy.length > 0 ? orderBy : ''
    this.setState({
      sortBy, orderBy
    }, () => {
      this.getDataDomainElementsList()
    });
  }

  render () {
    if (this.state.isLoading) {
      return <Loader/>;
    }
    const {
      dataLoading, openAddEditModal, editMode,viewMode ,domainElement, searchQuery, dataDomainElementsList,
      columns, openDeleteModal, totalRecords, dataDomainTypes, profileElements, tags
    } = this.state;
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <div className="main-body-c">
            <Header/>
            <div className="title-header-main">
              <div className="title-header">
                <h3>
                  Data Domain Element
                </h3>
                <Button className="custom-btn" variant="contained" color="primary"
                        onClick={() => this.openAddEditDomainElementModal(false, null,false)}>
                  Add New
                </Button>
              </div>
            </div>

            {openAddEditModal && (
              <AddEditDomainElementModal
                openModal={openAddEditModal}
                closeModal={this.closeAddEditDomainElementModal}
                saveDomainElement={this.saveDomainElement}
                editMode={editMode}
                lookupMap={this.state.lookupMap}
                viewMode = {viewMode}
                domainElement={JSON.parse(JSON.stringify(domainElement))}
                profileElements={profileElements}
                dataDomainTypes={dataDomainTypes}
                tags={tags}
              />
            )}

            {openDeleteModal && (
              <DeleteModal
                openModal={openDeleteModal}
                closeModal={this.closeDomainElementDeleteModal}
                deleteItem={this.deleteDomainElement}
                seletectedItem={domainElement}
              />
            )}

            <div className="content-main">
              <div className="table-actions">
                <DebounceInput
                  element={(props) => {
                    return (
                      <InputBase
                        className="search-input"
                        placeholder="Search Domain Element"
                        autoFocus
                        label="Search Domain Element"
                        variant="filled"
                        value={props.value}
                        onChange={props.onChange}
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon style={{color: "#475885"}}/>
                          </InputAdornment>
                        }
                      />
                    )
                  }}
                  className="search-input"
                  minLength={1}
                  debounceTimeout={500}
                  value={searchQuery}
                  onChange={event => this.setState({
                    searchQuery: event.target.value.trim(),
                    currentPage: 1
                  }, this.getDataDomainElementsList)}/>
              </div>
              <Paper className="table-component-main" elevation={2}>
                <DataTable
                  columns={columns}
                  data={dataDomainElementsList}
                  progressPending={dataLoading}
                  persistTableHead
                  keyField={'domainElementId'}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRecords}
                  paginationDefaultPage={1}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  sortServer = {true}
                  onSort={this.onSort}
                />
              </Paper>
            </div>
          </div>
        </div>
      </JssProvider>
    );
  }
}

export default DomainElementsList;
