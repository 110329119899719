export const ApiEndpoints = {
  GET_PROFILE: {
    path: "/profile/profile",
    method: "GET"
  },
  LOGIN: {
    path: "/auth/login",
    method: "POST"
  },
  VERIFY_OTP: {
    path: "/auth/login/patient/otp",
    method: "POST"
  },
  USER_ACCOUNT_RECOVERY: {
    path: "/auth/recoverAccount",
    method: "POST"
  },
  UPDATE_PASSWORD: {
    path: "/auth/recoverAccount/setup/newPassword",
    method: "POST"
  },
  VERIFY_CONFIRMATION_CODE: {
    path: "/auth/verificationCode",
    method: "POST"
  },
  PROVIDER_PROFILE: {
    path: "/profile/provider",
    method: "GET"
  },
  GET_PROVIDER_ORGANIZATIONS: {
    path: "/profile/provider/organizations",
    method: "GET"
  },
  SET_DEFAULT_ORGANIZATION: {
    path: "/profile/provider/organization/setDefault",
    method: "POST"
  },
  GET_CONVERSATION: {
    path: "/conversation/conversation/list/{organizationId}",
    method: "GET"
  },
  ASSIGN_CONVERSATION: {
    path: "/conversation/conversation/assign",
    method: "POST"
  },
  REGISTER_PLAYERID: {
    path: "/auth/player/{playerId}",
    method: "POST"
  },
  REMOVE_PLAYERID: {
    path: "/auth/removePlayer/{playerId}",
    method: "DELETE"
  },
  REFRESH_AUTH_TOKEN: {
    path: "/auth/token/refresh",
    method: "GET"
  },

  GET_PROFILE_DCT_BY_USER_ID: {
    path: "/profile/profile/dct/{userId}",
    method: "GET"
  },
  INITIATE_VIDEOCHAT: {
    path: "/conversation/initiateVideoChat/{userID}",
    method: "POST"
  },
  AVATAR_BY_USER_ID: {
    path: "/profile/profile/avatar/{userId}",
    method: "GET"
  },
  GET_CONNECTIONS: {
    path: "/profile/connections",
    method: "GET"
  },
  GET_DEMOGRAPHIC_DETAIL: {
    path: "/profile/demographics/{connectionId}",
    method: "GET"
  },
  UPDATE_DEMOGRAPHIC_DETAIL: {
    path: "/profile/demographics/{connectionId}",
    method: "PUT"
  },
  GET_SCHEDULING_DETAIL: {
    path: "/scheduling/cheatsheet/appointments/{connectionId}",
    method: "GET"
  },

  GET_OUTCOMES_DETAIL: {
    path: "/conversation/cheatsheet/outcomes/{userId}",
    method: "GET"
  },

  GET_PROFILE_ELEMENTS: {
    path: "/conversation/builder/profileElementsList",
    method: "GET"
  },

  ADD_NEW_PROFILE_ELEMENT: {
    path: "/conversation/builder/profileElement",
    method: "POST"
  },

  UPDATE_PROFILE_ELEMENT: {
    path: "/conversation/builder/profileElement",
    method: "PUT"
  },

  GET_DCT_LIST: {
    path: "/conversation/builder/dcts",
    method: "GET"
  },

  GET_TAGS_LIST: {
    path: "/conversation/builder/tags",
    method: "GET"
  },


  GET_PATIENTS_LIST: {
    path: "/profile/users/patients",
    method: "GET"
  },

  GET_WALLET: {
    path: "/billing/wallet/{connectionId}",
    method: "GET"
  },

  UPDATE_WALLET_BALANCE: {
    path: "/billing/wallet/cheatsheet/topup",
    method: "POST"
  },

  GET_USER_PROFILES_BY_SEARCH: {
    path: "/profile/users/search",
    method: "GET"
  },

  GET_PROVIDER_PUBLIC_DETAILS: {
    path: '/profile/provider/{providerId}',
    method: 'GET',
  },

  ADD_NEW_PROVIDER: {
    path: "/profile/provider/add",
    method: "POST"
  },
  GET_PROVIDER_PROFILE: {
    path: "/profile/provider",
    method: "GET"
  },
  GET_FEEDBACK_SUMMARY: {
    path: '/scheduling/telehealth/feedback/summary/{providerId}',
    method: 'GET',
  },

  GET_CONNECTIONS_BY_USER_ID: {
    path: "/profile/connections/activeConnectionList/{userId}",
    method: "GET"
  },

  SUGGEST_CONNECTION_REQUEST: {
    path: '/profile/connections/suggestConnection',
    method: 'POST'
  },
  UPLOAD_IMAGE: {
    path: '/profile/provider/uploadImage',
    method: 'POST'
  },
  UPDATE_PROVIDER_PROFILE: {
    path: '/profile/provider/{providerId}/updateProfile',
    method: 'POST'
  },
  FETCH_ALL_PROVIDERS: {
    path: '/profile/provider/all',
    method: 'GET'
  },
  REORDER_PROVIDERS: {
    path: '/profile/provider/reorder',
    method: 'POST'
  },
  FETCH_PROVIDER_SERVICES: {
    path: '/scheduling/schedule/providerService',
    method: 'GET'
  },
  FETCH_CLINICAL_SERVICES: {
    path: '/scheduling/schedule/providerServiceTypes',
    method: 'GET'
  },
  DELETE_SERVICE: {
    path: "/scheduling/schedule/deleteService/{serviceId}",
    method: "DELETE"
  },
  DELETE_CLINICAL_SERVICE: {
    path: "/scheduling/schedule/deleteProviderServiceType/{serviceName}",
    method: "DELETE"
  },

  UPDATE_SERVICE_STATUS: {
    path: "/scheduling/schedule/updateProviderServiceStatus",
    method: "POST"
  },
  ADD_NEW_SERVICE: {
    path: '/scheduling/schedule/service',
    method: 'POST'
  },
  ADD_NEW_CLINICAL_SERVICE: {
    path: '/scheduling/schedule/providerServiceType',
    method: 'POST'
  },
  UPDATE_SERVICE: {
    path: '/scheduling/schedule/service',
    method: 'PUT'
  },
  UPDATE_CLINICAL_SERVICE: {
    path: '/scheduling/schedule/providerServiceType',
    method: 'PUT'
  },

  GET_LEDGER: {
    path: '/billing/payments/ledger/aggregate',
    method: 'GET'
  },
  GET_GROUP_LEDGER: {
    path: '/billing/payments/ledger/donations',
    method: 'GET'
  },
  GET_LEDGER_DETAILS: {
    path: '/billing/payments/ledger/provider/{providerId}',
    method: 'GET'
  },

  GET_PROVIDER_SCHEDULE: {
    path: "/scheduling/schedule",
    method: "GET"
  },

  UPDATE_PROVIDER_SCHEDULE: {
    path: "/scheduling/schedule",
    method: "PUT"
  },

  PAY_PROVIDER_OUTSTANDING_AMOUNT: {
    path: "/billing/payments/ledger/providerPayment/{providerId}",
    method: "POST"
  },

  GET_PROVIDER_SERVICE_TYPES: {
    path: "/scheduling/schedule/providerServiceTypes",
    method: "GET"
  },

  GET_CONVERSATIONS_DETAIL: {
    path: "/conversation/builder/conversations",
    method: "GET"
  },

  GE_AUTOMATION_RULES_DETAIL: {
    path: "/conversation/builder/automationRulesList",
    method: "GET"
  },

  ADD_DCT: {
    path: "/conversation/builder/dct",
    method: "POST"
  },

  ADD_EVALUATION_DCT: {
    path: "/conversation/builder/evaluationDct",
    method: "POST"
  },
  GET_CONTENT_BLOCKS_BY_DCT: {
    path: "/conversation/builder/contentblocks/{dctId}",
    method: "GET"
  },
  GET_CONTENT_BLOCKS_BY_CONVERSATION: {
    path: "/conversation/builder/contentblocksByConversationId/{conversationId}",
    method: "GET"
  },
  ADD_NEW_CONTENT_BLOCK: {
    path: "/conversation/builder/contentblock",
    method: "POST"
  },
  SAVE_CONTENT_BLOCK: {
    path: "/conversation/builder/contentblock/{cbId}",
    method: "PUT"
  },
  UPDATE_DCT: {
    path: "/conversation/builder/dct/{dctId}",
    method: "PUT"
  },
  UPDATE_EVALUATION_DCT: {
    path: "/conversation/builder/evaluationDct/{dctId}",
    method: "PUT"
  },
  UPDATE_CONVERSATION: {
    path: "/conversation/builder/conversation/{conversationId}",
    method: "PUT"
  },
  ADD_CONVERSATION: {
    path: "/conversation/builder/conversation",
    method: "POST"
  },
  COPY_CONTENT_BLOCK: {
    path: "/conversation/builder/contentblock/{cbId}/copy",
    method: "POST"
  },
  DELETE_CONTENT_BLOCK: {
    path: "/conversation/builder/contentblock/{cbId}",
    method: "DELETE"
  },

  SAVE_CHOICE_TEMPLATE: {
    path: "/conversation/builder/saveChoiceTemplate",
    method: "POST"
  },

  GET_ALL_CHOICE_TEMPLATES: {
    path: "/conversation/builder/getAllChoiceTemplates",
    method: "GET"
  },
  GET_PERCENTILE_DISTRIBUTION_PROFILE_ELEMENT_BY_VALUES: {
    path: "/conversation/builder/profileElement/distribution/{profileElementId}",
    method: "GET"
  },

  GET_PROFILE_ELEMENT_DETAIL_BY_MONTH: {
    path: "/conversation/builder/profileElement/assignedByMonth/{profileElementId}",
    method: "GET"
  },

  GET_PROFILE_ELEMENT_DATA_USAGE: {
    path: "/conversation/builder/profileElement/dctUsage/{profileElementId}",
    method: "GET"
  },

  GET_CONVERSATION_ASSIGNMENT_REPORT: {
    path: "/conversation/builder/conversation/{conversationId}/graph",
    method: "GET"
  },

  GET_CONVERSATION_ASSIGNMENT_DETAIL: {
    path: "/conversation/builder/conversation/{conversationId}/patients",
    method: "GET"
  },

  GET_DCT_ASSIGNMENT_REPORT: {
    path: "/conversation/builder/dct/{dctId}/graph",
    method: "GET"
  },

  GET_DCT_ASSIGNMENT_DETAIL: {
    path: "/conversation/builder/dct/{dctId}/patients",
    method: "GET"
  },

  GET_DOMAIN_TYPES: {
    path: "/conversation/builder/domainTypes",
    method: "GET"
  },

  ADD_DOMAIN_TYPE: {
    path: "/conversation/builder/domainType",
    method: "POST"
  },

  UPDATE_DOMAIN_TYPE: {
    path: "/conversation/builder/domainType/{domainTypeId}",
    method: "PUT"
  },

  DELETE_DOMAIN_TYPE: {
    path: "/conversation/builder/domainType/{domainTypeId}",
    method: "DELETE"
  },

  UPGRADE_CONVERSATION_VERSION: {
    path: "/conversation/builder/conversation/{conversationId}/upgradeVersion",
    method: "POST"
  },
  PUBLISH_CONVERSATION_DRAFT: {
    path: "/conversation/builder/conversation/{conversationId}/publish",
    method: "POST"
  },
  CHECK_CONVERSATION_DRAFT_RIPPLES: {
    path: "/conversation/builder/conversation/{conversationId}/publish",
    method: "GET"
  },

  UPGRADE_DCT_VERSION: {
    path: "/conversation/builder/dct/{dctId}/upgradeVersion",
    method: "POST"
  },
  PUBLISH_DCT_DRAFT: {
    path: "/conversation/builder/dct/{dctId}/publish",
    method: "POST"
  },
  CHECK_DCT_DRAFT_RIPPLES: {
    path: "/conversation/builder/dct/{dctId}/publish",
    method: "GET"
  },
  LIST_APPOINTMENT_ELIGIBLE_PROVIDERS: {
    path: '/scheduling/appointment/listProviders',
    method: 'GET',
  },


  ADD_TAG: {
    path: "/conversation/builder/tag",
    method: "POST"
  },

  UPDATE_TAG: {
    path: "/conversation/builder/tag/{tagId}",
    method: "PUT"
  },
  GET_DCT_DETAIL: {
    path: "/conversation/builder/dct/{dctId}",
    method: "GET"
  },
  UPDATE_DCT_SCORING: {
    path: "/conversation/builder/dct/scoring/{dctId}",
    method: "PUT"
  },

  ADD_AUTOMATION_RULE: {
    path: "/conversation/builder/automationRule",
    method: "POST"
  },

  UPDATE_AUTOMATION_RULE: {
    path: "/conversation/builder/automationRule/{automationRuleId}",
    method: "PUT"
  },

  DELETE_AUTOMATION_RULE: {
    path: "/conversation/builder/automationRule/{automationRuleId}",
    method: "DELETE"
  },

  ADD_DOMAIN_ELEMENT: {
    path: "/conversation/builder/domainElement",
    method: "POST"
  },

  UPDATE_DOMAIN_ELEMENT: {
    path: "/conversation/builder/domainElement/{domainElementId}",
    method: "PUT"
  },

  DELETE_DOMAIN_ELEMENT: {
    path: "/conversation/builder/domainElement/{domainElementId}",
    method: "DELETE"
  },

  GET_DOMAIN_GROUPS: {
    path: "/conversation/builder/domainGroups",
    method: "GET"
  },

  ADD_DOMAIN_GROUP: {
    path: "/conversation/builder/domainGroup",
    method: "POST"
  },

  UPDATE_DOMAIN_GROUP: {
    path: "/conversation/builder/domainGroup/{domainGroupId}",
    method: "PUT"
  },

  DELETE_DOMAIN_GROUP: {
    path: "/conversation/builder/domainGroup/{domainGroupId}",
    method: "DELETE"
  },

  GET_DOMAIN_ELEMENTS: {
    path: "/conversation/builder/domainElements",
    method: "GET"
  },
  GET_TAG_REPORT: {
    path: "/conversation/builder/tags/{tagId}/graph",
    method: "GET"
  },
  GET_TAG_ASSIGNMENT_DETAILS: {
    path: "/conversation/builder/tags/{tagId}/patients",
    method: "GET"
  },
  GET_TAG_DETAIL: {
    path: "/conversation/builder/tag/{tagId}",
    method: "GET"
  },
  GET_DOMAIN_LOOKUP: {
    path: "/conversation/builder/domain/lookup",
    method: "GET"
  },
  SEARCH_AVAILABLE_SLOTS: {
    path: "/scheduling/appointment/masterSchedule",
    method: "POST"
  },
  GET_REVAMP_TYPES: {
    path: "/conversation/revamp/revampTypes",
    method: "GET"
  },
  ADD_REVAMP_TYPE: {
    path: "/conversation/revamp/revampType",
    method: "POST"
  },
  UPDATE_REVAMP_TYPE: {
    path: "/conversation/revamp/revampType",
    method: "PUT"
  },
  DELETE_REVAMP_TYPE: {
    path: "/conversation/revamp/revampType",
    method: "DELETE"
  },
  GET_PROVIDER_APPOINTMENTS: {
    path: "/scheduling/appointment/providers",
    method: "POST"
  },
  GET_APPOINTMENTS_REPORTS: {
    path: "/scheduling/report/appointments",
    method: "POST"
  },
  GET_ALL_SERVICES :{
    path: '/scheduling/schedule/getAllSystemServices',
    method: 'GET'
  },
  GET_ALL_GROUPS :{
    path: '/profile/groups/allGroups',
    method: 'GET'
  },
  GET_PLAN_ITEMS: {
    path: "/conversation/revamp/revampPlanItems",
    method: "GET"
  },
  ADD_NEW_PLAN_ITEM: {
    path: "/conversation/revamp/revampPlanItem",
    method: "POST"
  },

  UPDATE_PLAN_ITEM: {
    path: "/conversation/revamp/revampPlanItem",
    method: "PUT"
  },

  DELETE_PLAN_ITEM : {
    path: "/conversation/revamp/revampPlanItem",
    method: "DELETE"
  },
  UPDATE_PROVIDER_OPERATING_STATES: {
    path: '/profile/provider/operatingStates/{providerId}',
    method: 'PUT'
  },

  GET_ENGAGEMENT_LEVELS: {
    path: "/conversation/builder/levelOfEngagements",
    method: "GET"
  },
  ADD_NEW_ENGAGEMENT_LEVEL: {
    path: "/conversation/builder/levelOfEngagement",
    method: "POST"
  },

  UPDATE_ENGAGEMENT_LEVEL: {
    path: "/conversation/builder/levelOfEngagement/{levelOfEngagementId}",
    method: "PUT"
  },

  DELETE_ENGAGEMENT_LEVEL : {
    path: "/conversation/builder/levelOfEngagement/{levelOfEngagementId}",
    method: "DELETE"
  },
  GET_EVALUATIONS: {
    path: "/conversation/builder/evaluations",
    method: "GET"
  },
  ADD_NEW_EVALUATION: {
    path: "/conversation/builder/evaluations",
    method: "POST"
  },

  UPDATE_EVALUATION: {
    path: "/conversation/builder/evaluations/{evaluationId}",
    method: "PUT"
  },

};
