import React, {Component} from 'react';
import './model.scss'
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Checkbox from "@material-ui/core/Checkbox";
import {FormControlLabel} from "@material-ui/core";
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import {Close} from "@material-ui/icons"
import {
  NAME_REGEX,
  TAXONOMY_TYPES,
  IMPORTANCE_LEVELS,
  DOMAIN_TYPE_SPECIFICATIONS,
  DOMAIN_ORDER_BY
} from "../../../../constants/CommonConstants"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import {createCopy} from "../../../../utilities/CommonUtils"
import ModalBackdrop from "./ModalBackdrop"

class AddEditDomainTypeModal extends Component {

  constructor (props) {
    super(props);
    this.state = {
      domainTypeName: this.props.domainType?.name || "",
      metaDataSpec : this.mapPropsToState()
    }
    this.form = {}
  }

  /**
   * @function mapPropsToState
   * @description This method is used to map props to state.
   */
  mapPropsToState = () => {
    const {domainType} = this.props;
    let metaDataSpec = domainType?.metaData?.metaDataSpec || {};
    metaDataSpec = {
      importanceLevels: (metaDataSpec && metaDataSpec.importanceLevels && {...createCopy(IMPORTANCE_LEVELS), ...metaDataSpec.importanceLevels}) || createCopy(IMPORTANCE_LEVELS),
      requireDiagnosisInfo: metaDataSpec?.requireDiagnosisInfo || false,
      requireHistoryInfo: metaDataSpec?.requireHistoryInfo || false,
      requireInterferenceWithLifeInfo: metaDataSpec?.requireInterferenceWithLifeInfo || false,
      requireNotes: metaDataSpec?.requireNotes || false,
      requireRelatedToInfo: metaDataSpec?.requireRelatedToInfo || false,
      requireRevampInfo: metaDataSpec?.requireRevampInfo || false,
      requireRxInfo: metaDataSpec?.requireRxInfo || false,
      requireSpecification: metaDataSpec?.requireSpecification || false,
      requireSubstanceUse: metaDataSpec?.requireSubstanceUse || false,
      requireRelatedToMedicalCondition: metaDataSpec?.requireRelatedToMedicalCondition || false,
      requireRelatedToMedication: metaDataSpec?.requireRelatedToMedication || false,
      requireRelatedToSubstanceUse: metaDataSpec?.requireRelatedToSubstanceUse || false,
      requireRelatedToWithdrawal: metaDataSpec?.requireRelatedToWithdrawal || false,
      orderBy: metaDataSpec?.orderBy || ''
    }
    return metaDataSpec;
  }

  closeModal = () => {
    this.props.closeModal();
  }

  /**
   * @function validateDomainTypeName
   * @description This method is validate domain type name.
   */

  validateDomainTypeName = () => {
    const {domainTypeName} = this.state;
    let domainTypeNameError = false;
    if (domainTypeName.trim() === null || domainTypeName.trim() === '') {
      domainTypeNameError = true;
    } else if (domainTypeName && domainTypeName !== '') {
      domainTypeNameError = !NAME_REGEX.test(domainTypeName.trim());
    }
    return !domainTypeNameError;
  }

  validateOrderBy = ()=>{
    const {metaDataSpec} = this.state;
    return !(!metaDataSpec.orderBy || metaDataSpec.orderBy === '');

  };

  /**
   * @function isFormValid
   * @description This method is validate form values.
   */

  isFormValid = () => {
    if (!this.validateDomainTypeName()) {
      AlertUtil.showError("Invalid domain type name");
      return false;
    }
    if(!this.validateOrderBy()) {
      AlertUtil.showError("Order By Selection is required");
      return false;
    }
    return true;
  }

  /**
   * @function saveDomainType
   * @description This method is used to save domain type in the system.
   */


  saveDomainType = () => {
    if (this.isFormValid()) {
      const {domainTypeName, metaDataSpec} = this.state;
      const domainTypeRequest = {
        name: domainTypeName,
        type: TAXONOMY_TYPES.PATIENT_DATA_DOMAIN,
        metaData: {
          metaDataSpec
        },
        parent: "CONCEPT",
        children: [],
        tags: [],
      }
      this.props.saveDomainType(domainTypeRequest,this.props.editMode);
    }
  }

  /**
   * @function renderMetaDataSpecs
   * @description This method is used to render meta data specs values.
   */

  renderMetaDataSpecs = (fieldName) => {
    let {metaDataSpec} = this.state;
    return (
      <div key={fieldName} className="field-wrapper">
        <p>{DOMAIN_TYPE_SPECIFICATIONS[fieldName]}</p>
        <FormControlLabel
          value={fieldName}
          labelPlacement="start"
          control={
            <Checkbox
              disabled={this.props.locked}
              className="dct-check"
              icon={<CircleUnchecked/>}
              checkedIcon={<CircleCheckedFilled/>}
              id="requireRelatedToInfo"
              color="primary"
              checked={metaDataSpec[fieldName]}
              required={true}
              onChange={(e) => {
                this.handleMetaDataSpecsChange(fieldName,false);
              }}
            />
          }
        />
      </div>
    )
  }

  /**
   * @function handleMetaDataSpecsChange
   * @description This method is used to handle Meta Data Specs Change.
   */

  handleMetaDataSpecsChange = (fieldName,importanceLevelsChange) => {
    let {metaDataSpec} = this.state;
    if(importanceLevelsChange){
      metaDataSpec.importanceLevels[fieldName] = !metaDataSpec.importanceLevels[fieldName];
    }else{
      metaDataSpec[fieldName] = !metaDataSpec[fieldName];
    }
    this.setState({metaDataSpec});
  };

  render () {
    const {domainTypeName, metaDataSpec} = this.state;
    return (
      <div className="modal-wrapper">
        <Modal
          open={this.props.openModal}
          onClose={this.closeModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropComponent={ModalBackdrop}
        >
          <div className="modal-main">

            <Button className="modal-close" onClick={this.closeModal}><Close/></Button>
            <h2
              id="builder-modal-title">{this.props.domainType ? ((this.props.locked?'View':'Edit')+ ' Data Domain type') : 'Add New Data Domain type'}</h2>

            {this.props.locked && (
              <div className="main-banner">
                <p>This type is locked for editing because it is inherited by domain elements</p>
              </div>
            )}
            <div className="field-wrapper">
              <FormControl>
                <TextField
                  id="name"
                  label="Name"
                  className='edit-input'
                  placeholder="Domain Type Name"
                  disabled={this.props.locked}
                  value={domainTypeName}
                  ref={(field) => {this.form.domainTypeName = field;}}
                  onChange={(e) => {
                    this.setState({domainTypeName: e.target.value});
                  }}
                />
              </FormControl>
            </div>
            <h3>Importance Level</h3>
            <div className="modal-sections-main">
              <div className="importance-level-main">
                <div className="importance-level">
                  {Object.keys(metaDataSpec.importanceLevels)
                    .filter(key=>{
                      if(this.props.locked) {
                        return metaDataSpec.importanceLevels[key];
                      }
                      return true;
                    })
                    .map(key => {
                    return (
                      <div key={key} className="field-wrapper">
                        <FormControlLabel className="select-option" control={
                          <Checkbox
                            className="dct-check"
                            disabled={this.props.locked}
                            icon={<CircleUnchecked/>}
                            checkedIcon={<CircleCheckedFilled/>}
                            checked={metaDataSpec.importanceLevels[key]}
                            onChange={()=>this.handleMetaDataSpecsChange(key,true)}/>
                        } label={key}/>
                      </div>);
                  })}
                </div>
                <div>
                  <h3>Order By</h3>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Order By </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={metaDataSpec.orderBy || ''}
                      onChange={({target}) => {
                        metaDataSpec.orderBy = target.value;
                        this.setState({
                          metaDataSpec
                        });
                      }}
                      disabled={this.props.locked}
                    >
                      {Object.keys(DOMAIN_ORDER_BY)
                        .map((key, index) => {
                          return (
                            <MenuItem key={`orderBy-key-${index}-${key}`}
                                      value={key}>{DOMAIN_ORDER_BY[key]}</MenuItem>
                          )
                        })}
                    </Select>
                  </FormControl>
                </div>
                <div className="second-section">
                  {Object.keys(DOMAIN_TYPE_SPECIFICATIONS)
                    .filter(key=>{
                      if(this.props.locked) {
                        return metaDataSpec[key];
                      }
                      return true;
                    })
                    .map(key => {
                    return (
                      this.renderMetaDataSpecs(key)
                    )
                  })
                  }
                </div>
                <div className="btn-wrapper">
                  {
                    !this.props.locked && (
                      <Button disabled={this.props.locked} onClick={this.saveDomainType} className="custom-btn" variant="contained"
                              color="primary">Save</Button>
                    )
                  }

                  <Button onClick={this.closeModal} className="custom-btn" variant="contained"
                          color="primary">{this.props.locked?'Close': 'Cancel'}</Button>
                </div>
              </div>
            </div>

          </div>
        </Modal>
      </div>);
  }
}

export default AddEditDomainTypeModal;
