import React, {Component} from 'react';
import './CreateService.scss';
import TopSectionComponent from '../../../shared/top-section/TopSectionComponent';
import FooterComp from '../../../../layout/Footer';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import {Loader} from '../../../shared/loader';
import Switch from "react-switch";
import ScheduleService from "../../../../services/ScheduleService"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import {DEFAULT_STATES_OPTIONS, NAME_REGEX,PROVIDER_SIGN_OFF_ROLES} from "../../../../constants/CommonConstants"
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {Multiselect} from 'multiselect-react-dropdown';
import Header from "../../../../layout/Header"
import ProfileService from "../../../../services/ProfileService"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

class CreateService extends Component {

  constructor (props) {
    super(props);
    const service = this.props.location.state;
    this.editMode = service.active !== false;
    this.serviceName = this.editMode ? service.name : '';
    console.log({service})
    this.state = {
      isLoading: false,
      serviceName: this.serviceName,
      buffer: this.editMode ? service.bufferTime : 0,
      duration: this.editMode ? service.duration : 0,
      desc: this.editMode ? service.description : '',
      cost: this.editMode ? service.cost : 0,
      marketCost: this.editMode ? service.marketCost : 0,
      recommendedCost: this.editMode ? service.recommendedCost : 0,
      id: this.editMode ? service.id : null,
      privateService: this.editMode ? service.privateService : false,
      providerProfile: null,
      serviceTypes: [],
      signOffRole: '',
      selectedServiceTypes: this.editMode && service.serviceTypes ? this.populateMultiSelectList(service.serviceTypes) : [],
      operatingStates: this.editMode ? this.populateMultiSelectList(service?.operatingStates) : [],
      stateUsageInAppointment: this.editMode ? service.stateUsageInAppointment : false,
      systemService: this.editMode ? service?.systemService : false,
      requireSupervisorSignOff: this.editMode ? service.requireSupervisorSignOff : false
    };
    this.form = {};
  }

  populateMultiSelectList = (list) => {
    if (list) {
      return list?.filter(item => item).map((name, index) => {
        return {
          id: index,
          name: name
        }
      });
    }
    return null
  }

  /**
   * @function validateServiceName
   * @description This method is used to validate service name .
   */

  validateServiceName = () => {
    const {serviceName} = this.state;
    let serviceNameError = false;
    if (serviceName.trim() === null || serviceName.trim() === '') {
      serviceNameError = true;
    } else if (serviceName && serviceName !== '') {
      serviceNameError = !NAME_REGEX.test(serviceName.trim());
    }

    return !serviceNameError;
  }

  /**
   * @function validateStateValue
   * @description This method is used to validate state list .
   */

  validateStateList = () => {
    const {operatingStates} = this.state;
    return operatingStates && operatingStates?.length > 0;
  }

  /**
   * @function validateCost
   * @description This method is used to validate Cost value .
   */

  validateCost = (type) => {
    const costRegex = /^\d+\.\d{0,2}$/;
    let cost = null;
    if (type === 'MARKET_COST') {
      cost = this.state.marketCost + "";
    } else if (type === 'RECOMMENDED_COST') {
      cost = this.state.recommendedCost + "";
    } else {
      cost = this.state.cost + "";
    }

    if (cost.startsWith('.')) {
      cost = '0' + cost;
    }
    if (cost.indexOf('.') === -1) {
      cost = cost + '.0';
    }
    if (cost.indexOf('.') === cost.length - 1) {
      return false;
    }
    const costError = !costRegex.test(cost);
    return !costError;
  };

  /**
   * @function validateDurationTime
   * @description This method is used to validate duration time .
   */
  validateDurationTime = () => {
    const {serviceDuration, serviceBufferTime} = this.state;
    if (serviceDuration === '') {
      this.setState({serviceDurationError: true});
      return false;
    } else if (serviceBufferTime === '') {
      this.setState({serviceBufferTimeError: true});
      return false;
    }
    return true;
  }
  isProviderOperatingStateIncludeServiceState = () => {
    let {operatingStates, stateUsageInAppointment} = this.state;
    const providerOperatingStates = this.providerProfile.operatingStates
    operatingStates = operatingStates.map(item => {
      return item.name;
    })
    let isExists = false;
    if (stateUsageInAppointment) {
      if (providerOperatingStates?.some(selectedState => operatingStates.includes(selectedState))) {
        isExists = true;
        console.log({isExists: isExists})
      }
      return isExists;
    } else
      return true;
  }
  /**
   * @function isFormValid
   * @description This method is used to validate form values.
   */

  isFormValid = () => {

    if (!this.validateServiceName()) {
      AlertUtil.showError("Invalid service name");
      return false;
    }
    if (this.state.stateUsageInAppointment && !this.validateStateList()) {
      AlertUtil.showError("Please select one state");
      return false;
    }
   /*if (!this.isProviderOperatingStateIncludeServiceState()) {
      AlertUtil.showError("Please select minimum one state for services, in which you are actively operating");
      return false;
    }
    */

    if (!this.validateCost()) {
      AlertUtil.showError("Please enter valid cost up to 2 decimal places");
      return false;
    }

    if (!this.validateCost('MARKET_COST')) {
      AlertUtil.showError("Please enter valid market cost up to 2 decimal places");
      return false;
    }

    if (!this.validateCost('RECOMMENDED_COST')) {
      AlertUtil.showError("Please enter valid recommended cost up to 2 decimal places");
      return false;
    }

    if (!this.validateDurationTime()) {
      AlertUtil.showError("Invalid Duration");
      return false;
    }
    if (Number(this.state.cost) > 2000) {
      AlertUtil.showError("Cost cannot exceed by $2000");
      return false;
    }

    if (Number(this.state.marketCost) > 2000) {
      AlertUtil.showError("Market cost cannot exceed by $2000");
      return false;
    }

    if (Number(this.state.recommendedCost) > 2000) {
      AlertUtil.showError("Recommended cost cannot exceed by $2000");
      return false;
    }

    if (this.state.selectedServiceTypes.length < 1) {
      AlertUtil.showError("Select Service Type");
      return false;
    }

    return true;

  };

  componentDidMount = async () => {
    await this.getProviderServiceTypes();
    await this.getProviderPublicDetails();

  };

  getProviderPublicDetails = async () => {
    const providerId = this.props.location.state.providerProfile.providerId;
    if (providerId) {
      this.providerProfile = await ProfileService.getProviderPublicDetails(providerId)
      this.setState({providerProfile:this.providerProfile,signOffRole:this.providerProfile.signOffRole})
    }
  }

  /**
   * @function getProviderServiceTypes
   * @description This method is used to get provider service types.
   */
  getProviderServiceTypes = async () => {
    this.setState({isLoading: true});
    const response = await ScheduleService.getProviderServiceType();
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({isLoading: false});
    } else {
      const {serviceTypes} = response;
      const serviceTypesList = serviceTypes.filter(serviceType => serviceType.name).map((serviceType, index) => {
        return {
          id: index,
          name: serviceType.name,
        }
      });
      this.setState({isLoading: false, serviceTypes: serviceTypesList});
    }
  };

  /**
   * @function saveService
   * @description This method is used to save service.
   */
  saveService = async () => {
    if (this.isFormValid()) {
      this.setState({isLoading: true});
      let {
        id, duration, buffer, serviceName, cost, marketCost, recommendedCost, desc,
        privateService, selectedServiceTypes, operatingStates, stateUsageInAppointment, requireSupervisorSignOff
      } = this.state;
      selectedServiceTypes = selectedServiceTypes.map(serviceType => serviceType.name);
      const providerId = this.props.match.params.providerId;
      const serviceRequest = {
        serviceId: id,
        duration: duration,
        buffer: buffer,
        name: serviceName.trim(),
        cost: parseFloat(cost),
        marketCost: parseFloat(marketCost),
        recommendedCost: parseFloat(recommendedCost),
        description: desc ? desc.trim() : '',
        privateService: privateService,
        serviceTypes: selectedServiceTypes,
        requireSupervisorSignOff,
        operatingStates: operatingStates?.length > 0 ? operatingStates?.map(state => state.name) : [],
        stateUsageInAppointment
      }
      let serviceCall = ScheduleService.addNewService;
      if (this.editMode) {
        serviceCall = ScheduleService.updateService;
      }
      const response = await serviceCall(serviceRequest, providerId);
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({isLoading: false});
      } else {
        AlertUtil.showSuccess(this.editMode ? "Service Updated Successfully" : "New Service Added");
        this.props.history.goBack();
      }

    }
  };

  /**
   * @function onSelect
   * @description This method is used to select service type.
   */
  onSelect = (selectedList, selectedItem) => {
    this.setState({selectedServiceTypes: [...this.state.selectedServiceTypes, selectedItem]});
  };

  /**
   * @function onRemove
   * @description This method is used to remove selected service type.
   */
  onRemove = (selectedList, removedItem) => {
    const {selectedServiceTypes} = this.state;
    const updatedServiceTypes = selectedServiceTypes.filter((serviceType) => serviceType.id !== removedItem.id);
    this.setState({selectedServiceTypes: updatedServiceTypes});
  };

  /**
   * @function onSelectState
   * @description This method is used to add state in operating list.
   */
  onSelectState = (operatingStates) => {
    this.setState({operatingStates})
  }

  /**
   * @function onRemoveState
   * @description This method is used to remove state from operating state list.
   */
  onRemoveState = (selectedList, removedItem) => {
    let {operatingStates} = this.state;
    operatingStates = operatingStates.filter(state => state.id !== removedItem.id);
    this.setState({operatingStates});
  }

  /**
   * @function getDefaultStateOptions
   * @description This method is used to get default state options.
   */
  getDefaultStateOptions = () => {
    return DEFAULT_STATES_OPTIONS.map((state, index) => {
      return {
        id: index,
        name: state
      }
    })
  }

  /**
   * @function renderStateSection
   * @description This method is used to render state section.
   */
  renderStateSection = () => {
    const {operatingStates, stateUsageInAppointment} = this.state;
    const defaultStateOptions = this.getDefaultStateOptions()
    return (
      <div>
        <div className="single-edit-div">
          <p className="field-name">State Used In Appointment: </p>
          <Switch
            offColor={'#969fa8'}
            onColor={'#3fb2fe'}
            checkedIcon={false}
            uncheckedIcon={false}
            onChange={() => {
              this.setState({
                operatingStates: !stateUsageInAppointment ? operatingStates : [],
                stateUsageInAppointment: !stateUsageInAppointment
              })
            }}
            checked={stateUsageInAppointment}/>
        </div>
        {stateUsageInAppointment && (
          <div className="single-edit-div">
            <p className="field-name">State{operatingStates?.length > 1 ? "s" : ""}</p>
            <Multiselect
              options={defaultStateOptions}
              placeholder={operatingStates && operatingStates?.length > 0 ? "" : "Select State"}
              selectedValues={operatingStates}
              onSelect={this.onSelectState}
              onRemove={this.onRemoveState}
              displayValue="name"
            />
          </div>
        )}
      </div>
    )
  }

  render () {
    const {signOffRole,providerProfile}=this.state
    console.log({providerProfile:providerProfile})
    if (this.state.isLoading) {
      return (
        <Loader/>
      );
    }
    const {systemService, selectedServiceTypes, serviceTypes, requireSupervisorSignOff} = this.state;
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <Header/>
          <TopSectionComponent
            title={this.editMode ? 'Edit Service ' + this.serviceName : 'Create New Service for ' + this.props.location.state.fullName}
            goBack={() => {
              this.props.history.goBack();
            }} actionButtons={[
            {
              onClick: this.saveService,
              text: 'Save'
            }
          ]}/>
          <div className={'create-service-container'}>
            <div className="create-service-inner">
              <div className="single-edit-div">
                <p className="field-name">Service Name: </p>
                <input
                  id="serviceName"
                  className='edit-input'
                  placeholder="Service Name"
                  value={this.state.serviceName}
                  ref={(field) => {this.form.serviceName = field;}}
                  onChange={(e) => {
                    this.setState({serviceName: e.target.value});
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      this.form.duration.focus();
                    }
                  }}
                />
              </div>
              <div className="single-edit-div">
                <p className="field-name">Service Type{selectedServiceTypes.length > 1 ? "s" : ""}</p>
                <Multiselect
                  options={serviceTypes}
                  placeholder={selectedServiceTypes && selectedServiceTypes.length > 0 ? "" : "Select Service Type"}
                  selectedValues={selectedServiceTypes}
                  onSelect={this.onSelect}
                  onRemove={this.onRemove}
                  displayValue="name"
                />
              </div>
              {signOffRole === PROVIDER_SIGN_OFF_ROLES.DEFAULT && (
              <div className="single-edit-div">
                <p className="field-name">Required Supervisor SignOff: </p>
                <Switch
                  offColor={'#969fa8'}
                  onColor={'#3fb2fe'}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={() => {
                    this.setState({
                      requireSupervisorSignOff: !requireSupervisorSignOff
                    })
                  }}
                  checked={requireSupervisorSignOff}/>
              </div>)}
              {this.renderStateSection()}
              <div className="single-edit-div">
                <p className="field-name">Cost: </p>
                <input
                  id="cost"
                  className='edit-input'
                  type="number"
                  placeholder="0"
                  value={this.state.cost}
                  ref={(field) => {this.form.cost = field;}}
                  onChange={(e) => {
                    this.setState({cost: e.target.value});
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      this.form.marketCost.focus();
                    }
                  }}
                />
              </div>
              <div className="single-edit-div">
                <p className="field-name">Market Cost: </p>
                <input
                  id="marketCost"
                  className='edit-input'
                  type="number"
                  placeholder="0"
                  value={this.state.marketCost}
                  ref={(field) => {this.form.marketCost = field;}}
                  onChange={(e) => {
                    this.setState({marketCost: e.target.value});
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      this.form.recommendedCost.focus();
                    }
                  }}
                />
              </div>
              <div className="single-edit-div">
                <p className="field-name">Recommended Cost: </p>
                <input
                  id="recommendedCost"
                  className='edit-input'
                  type="number"
                  placeholder="0"
                  value={this.state.recommendedCost}
                  ref={(field) => {this.form.recommendedCost = field;}}
                  onChange={(e) => {
                    this.setState({recommendedCost: e.target.value});
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      this.form.desc.focus();
                    }
                  }}
                />
              </div>
              <div className="single-edit-div">
                <p className="field-name">Service Description: </p>
                <input
                  id="desc"
                  className='edit-input'
                  type="text"
                  placeholder="Service Description"
                  value={this.state.desc}
                  ref={(field) => {this.form.desc = field;}}
                  onChange={(e) => {
                    this.setState({desc: e.target.value});
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {

                    }
                  }}
                />
              </div>
              <div className="single-edit-div">
                <p className="field-name">Duration: </p>
                <Select
                  className={'service-multiselect'}
                  value={this.state.duration}
                  onChange={({target}) => {
                    this.setState({
                      duration: target.value
                    })
                  }}
                >
                  {
                    [0, 15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180].map(value => <MenuItem
                      key={'duration-' + value} value={value}>{value}</MenuItem>)
                  }
                </Select> Minutes

              </div>
              <div className="single-edit-div">
                <p className="field-name">Buffer Time: </p>
                <Select
                  className={'service-multiselect'}
                  value={this.state.buffer}
                  onChange={({target}) => {
                    this.setState({
                      buffer: target.value
                    })
                  }}
                >
                  {
                    [0, 15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180].map(value => <MenuItem
                      key={'buffer-' + value} value={value}>{value}</MenuItem>)
                  }
                </Select> Minutes
              </div>
              {!systemService && (<div className="single-edit-div">
                  <p className="field-name">Private Service: </p>
                  <Switch
                    offColor={'#969fa8'}
                    onColor={'#3fb2fe'}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={() => {
                      this.setState({
                        privateService: !this.state.privateService
                      })
                    }}
                    checked={this.state.privateService}/>
                </div>
              )}
            </div>
          </div>
          <footer>
            <FooterComp/>
          </footer>
        </div>
      </JssProvider>
    );
  }
}

export default CreateService;
