import HttpClient from '../utilities/HttpClient';
import {ApiEndpoints} from '../constants/ApiEndpoints';

export default class ConversationService {
  static getOutcomesDetail (userId) {
    return new HttpClient().request(ApiEndpoints.GET_OUTCOMES_DETAIL, {userId}, null, null, null, true);
  }

  static getConversationsList (searchQuery, pageNumber, pageSize, orderBy, sortBy, status = '') {
    searchQuery = searchQuery ? searchQuery : ''
    pageNumber = pageNumber ? pageNumber : 0
    pageSize = pageSize ? pageSize : 10000
    orderBy = orderBy ? orderBy : ''
    sortBy = sortBy ? sortBy : []
    const queryParams = {searchQuery, pageNumber, pageSize, orderBy, sortBy, status};

    return new HttpClient().request(ApiEndpoints.GET_CONVERSATIONS_DETAIL, null, queryParams, null, null, true);
  }

  static getProfileElementsList (searchQuery, type, method, pageNumber, pageSize, orderBy, sortBy) {
    searchQuery = searchQuery ? searchQuery : ''
    type = type ? type : ''
    method = method ? method : ''
    pageNumber = pageNumber ? pageNumber : 0
    pageSize = pageSize ? pageSize : 10000
    orderBy = orderBy ? orderBy : ''
    sortBy = sortBy ? sortBy : []
    const queryParams = {searchQuery, type, method, pageNumber, pageSize, orderBy, sortBy};
    return new HttpClient().request(ApiEndpoints.GET_PROFILE_ELEMENTS, null, queryParams, null, null, true);
  }

  static addProfileElement (payload) {
    return new HttpClient().request(ApiEndpoints.ADD_NEW_PROFILE_ELEMENT, null, null, null, payload, true);
  }

  static updateProfileElement (payload, profileElementId) {
    return new HttpClient().request(ApiEndpoints.UPDATE_PROFILE_ELEMENT, null, {profileElementId}, null, payload, true);
  }

  static getDctsList (searchQuery, pageNumber, pageSize, orderBy, sortBy, status = '',type) {
    searchQuery = searchQuery ? searchQuery : ''
    pageNumber = pageNumber ? pageNumber : 0
    pageSize = pageSize ? pageSize : 10000
    orderBy = orderBy ? orderBy : ''
    sortBy = sortBy ? sortBy : []
    type = type ? type :""
    const queryParams = {searchQuery, pageNumber, pageSize, orderBy, sortBy, status , type};
    return new HttpClient().request(ApiEndpoints.GET_DCT_LIST, null, queryParams, null, null, true);
  }

  static getAutomationRulesList (pageNumber, pageSize, orderBy, sortBy, eventType, actionType, filterType) {
    pageNumber = pageNumber ? pageNumber : 0
    pageSize = pageSize ? pageSize : 10000
    orderBy = orderBy ? orderBy : ''
    sortBy = sortBy ? sortBy : []
    eventType = eventType ? eventType : ''
    actionType = actionType ? actionType : ''
    filterType = filterType ? filterType : ''
    const queryParams = {pageNumber, pageSize, orderBy, sortBy, eventType, actionType, filterType};
    return new HttpClient().request(ApiEndpoints.GE_AUTOMATION_RULES_DETAIL, null, queryParams, null, null, true);
  }

  static addDct (dct) {
    return new HttpClient().request(ApiEndpoints.ADD_DCT, null, null, null, dct, true);
  }
  static addEvaluationDct (dct) {
    return new HttpClient().request(ApiEndpoints.ADD_EVALUATION_DCT, null, null, null, dct, true);
  }

  static getContentblocksByDct (dctId) {
    return new HttpClient().request(ApiEndpoints.GET_CONTENT_BLOCKS_BY_DCT, {dctId}, null, null, null, true);
  }

  static getContentBlocksByConversation (conversationId) {
    return new HttpClient().request(ApiEndpoints.GET_CONTENT_BLOCKS_BY_CONVERSATION, {conversationId}, null, null, null, true);
  }

  static addContentBlock (request) {
    return new HttpClient().request(ApiEndpoints.ADD_NEW_CONTENT_BLOCK, null, null, null, request, true);
  }

  static saveContentBlock (cbId, contentBlock) {
    return new HttpClient().request(ApiEndpoints.SAVE_CONTENT_BLOCK, {cbId}, null, null, contentBlock, true);
  }

  static updateDCT (dctId, request) {
    return new HttpClient().request(ApiEndpoints.UPDATE_DCT, {dctId}, null, null, request, true);
  }
  static updateEvaluationDCT (dctId, request) {
    return new HttpClient().request(ApiEndpoints.UPDATE_EVALUATION_DCT, {dctId}, null, null, request, true);
  }

  static updateConversation (conversationId, request) {
    return new HttpClient().request(ApiEndpoints.UPDATE_CONVERSATION, {conversationId}, null, null, request, true);
  }

  static addConversation (request) {
    return new HttpClient().request(ApiEndpoints.ADD_CONVERSATION, null, null, null, request, true);
  }

  static copyContentBlock (cbId) {
    return new HttpClient().request(ApiEndpoints.COPY_CONTENT_BLOCK, {cbId}, null, null, null, true);
  }

  static deleteContentBlock (cbId) {
    return new HttpClient().request(ApiEndpoints.DELETE_CONTENT_BLOCK, {cbId}, null, null, null, true);
  }

  static saveChoiceTemplate (request) {
    return new HttpClient().request(ApiEndpoints.SAVE_CHOICE_TEMPLATE, null, null, null, request, true);
  }

  static getAllChoiceTemplates () {
    return new HttpClient().request(ApiEndpoints.GET_ALL_CHOICE_TEMPLATES, null, null, null, null, true);
  }

  static getPercentileDistributionOfProfileElementByValues (profileElementId) {
    return new HttpClient().request(ApiEndpoints.GET_PERCENTILE_DISTRIBUTION_PROFILE_ELEMENT_BY_VALUES, {profileElementId}, null, null, null, true);
  }

  static getProfileElementDetailByMonth (profileElementId) {
    return new HttpClient().request(ApiEndpoints.GET_PROFILE_ELEMENT_DETAIL_BY_MONTH, {profileElementId}, null, null, null, true);
  }

  static getProfileElementDataUsage (profileElementId) {
    return new HttpClient().request(ApiEndpoints.GET_PROFILE_ELEMENT_DATA_USAGE, {profileElementId}, null, null, null, true);
  }

  static getConversationAssignmentReport (conversationId, period) {
    return new HttpClient().request(ApiEndpoints.GET_CONVERSATION_ASSIGNMENT_REPORT, {conversationId}, {period}, null, null, true);
  }

  static getConversationAssignmentDetail (conversationId) {
    return new HttpClient().request(ApiEndpoints.GET_CONVERSATION_ASSIGNMENT_DETAIL, {conversationId}, null, null, null, true);
  }

  static getDctAssignmentReport (dctId, period) {
    return new HttpClient().request(ApiEndpoints.GET_DCT_ASSIGNMENT_REPORT, {dctId}, {period}, null, null, true);
  }

  static getDctAssignmentDetail (dctId) {
    return new HttpClient().request(ApiEndpoints.GET_DCT_ASSIGNMENT_DETAIL, {dctId}, null, null, null, true);
  }

  static getDataDomainTypes (searchQuery, pageNumber, pageSize, orderBy, sortBy) {
    searchQuery = searchQuery ? searchQuery : ''
    pageNumber = pageNumber ? pageNumber : 0
    pageSize = pageSize ? pageSize : 10000
    orderBy = orderBy ? orderBy : ''
    sortBy = sortBy ? sortBy : []
    const queryParams = {searchQuery, pageNumber, pageSize, orderBy, sortBy};
    return new HttpClient().request(ApiEndpoints.GET_DOMAIN_TYPES, null, queryParams, null, null, true);
  }

  static addDomainType (request) {
    return new HttpClient().request(ApiEndpoints.ADD_DOMAIN_TYPE, null, null, null, request, true);
  }

  static updateDomainType (request, domainTypeId) {
    return new HttpClient().request(ApiEndpoints.UPDATE_DOMAIN_TYPE, {domainTypeId}, null, null, request, true);
  }

  static deleteDomainType (domainTypeId) {
    return new HttpClient().request(ApiEndpoints.DELETE_DOMAIN_TYPE, {domainTypeId}, null, null, null, true);
  }

  static upgradeConversationVersion (conversationId) {
    return new HttpClient().request(ApiEndpoints.UPGRADE_CONVERSATION_VERSION, {conversationId}, null, null, null, true);
  }

  static publishConversation (conversationId) {
    return new HttpClient().request(ApiEndpoints.PUBLISH_CONVERSATION_DRAFT, {conversationId}, null, null, null, true);
  }

  static getConversationRippleCounts (conversationId) {
    return new HttpClient().request(ApiEndpoints.CHECK_CONVERSATION_DRAFT_RIPPLES, {conversationId}, null, null, null, true);
  }

  static upgradeDctVersion (dctId) {
    return new HttpClient().request(ApiEndpoints.UPGRADE_DCT_VERSION, {dctId}, null, null, null, true);
  }

  static publishDCT (dctId) {
    return new HttpClient().request(ApiEndpoints.PUBLISH_DCT_DRAFT, {dctId}, null, null, null, true);
  }

  static getDCTRipplesCount (dctId) {
    return new HttpClient().request(ApiEndpoints.CHECK_DCT_DRAFT_RIPPLES, {dctId}, null, null, null, true);
  }

  static getDataDomainElements (searchQuery, pageNumber, pageSize, orderBy, sortBy) {
    searchQuery = searchQuery ? searchQuery : ''
    pageNumber = pageNumber ? pageNumber : 0
    pageSize = pageSize ? pageSize : 10000
    orderBy = orderBy ? orderBy : ''
    sortBy = sortBy ? sortBy : []
    const queryParams = {searchQuery, pageNumber, pageSize, orderBy, sortBy};
    return new HttpClient().request(ApiEndpoints.GET_DOMAIN_ELEMENTS, null, queryParams, null, null, true);
  }

  static listProviders () {
    return new HttpClient().request(ApiEndpoints.LIST_APPOINTMENT_ELIGIBLE_PROVIDERS);
  }

  static deleteAutomationRule (automationRuleId) {
    return new HttpClient().request(ApiEndpoints.DELETE_AUTOMATION_RULE, {automationRuleId}, null, null, null, true);
  }

  static addAutomationRule (automationRuleRequest) {
    return new HttpClient().request(ApiEndpoints.ADD_AUTOMATION_RULE, null, null, null, automationRuleRequest, true);
  }

  static updateAutomationRule (automationRuleRequest, automationRuleId) {
    return new HttpClient().request(ApiEndpoints.UPDATE_AUTOMATION_RULE, {automationRuleId}, null, null, automationRuleRequest, true);
  }

  static addTag (tagRequest) {
    return new HttpClient().request(ApiEndpoints.ADD_TAG, null, null, null, tagRequest, true);
  }

  static updateTag (tagRequest, tagId) {
    return new HttpClient().request(ApiEndpoints.UPDATE_TAG, {tagId}, null, null, tagRequest, true);
  }

  static getDCTDetails (dctId) {
    return new HttpClient().request(ApiEndpoints.GET_DCT_DETAIL, {dctId}, null, null, null, true);
  }

  static updateDCTScoring (scoring, dctId) {
    return new HttpClient().request(ApiEndpoints.UPDATE_DCT_SCORING, {dctId}, null, null, scoring, true);
  }

  static getDomainGroups (searchQuery, pageNumber, pageSize, orderBy, sortBy) {
    searchQuery = searchQuery ? searchQuery : ''
    pageNumber = pageNumber ? pageNumber : 0
    pageSize = pageSize ? pageSize : 10000
    orderBy = orderBy ? orderBy : ''
    sortBy = sortBy ? sortBy : []
    const queryParams = {searchQuery, pageNumber, pageSize, orderBy, sortBy};
    return new HttpClient().request(ApiEndpoints.GET_DOMAIN_GROUPS, null, queryParams, null, null, true);
  }

  static getTagsList (searchQuery, pageNumber, pageSize, orderBy, sortBy) {
    searchQuery = searchQuery ? searchQuery : ''
    pageNumber = pageNumber ? pageNumber : 0
    pageSize = pageSize ? pageSize : 10000
    orderBy = orderBy ? orderBy : ''
    sortBy = sortBy ? sortBy : []
    const queryParams = {searchQuery, pageNumber, pageSize, orderBy, sortBy};
    return new HttpClient().request(ApiEndpoints.GET_TAGS_LIST, null, queryParams, null, null, true);
  }

  static addDomainElement (addDomainElementRequest) {
    return new HttpClient().request(ApiEndpoints.ADD_DOMAIN_ELEMENT, null, null, null, addDomainElementRequest, true);
  }

  static updateDomainElement (updateDomainElementRequest, domainElementId) {
    return new HttpClient().request(ApiEndpoints.UPDATE_DOMAIN_ELEMENT, {domainElementId}, null, null, updateDomainElementRequest, true);
  }

  static deleteDomainElement (domainElementId) {
    return new HttpClient().request(ApiEndpoints.DELETE_DOMAIN_ELEMENT, {domainElementId}, null, null, null, true);
  }

  static addDomainGroup (addDomainGroupRequest) {
    return new HttpClient().request(ApiEndpoints.ADD_DOMAIN_GROUP, null, null, null, addDomainGroupRequest, true);
  }

  static updateDomainGroup (updateDomainGroupRequest, domainGroupId) {
    return new HttpClient().request(ApiEndpoints.UPDATE_DOMAIN_GROUP, {domainGroupId}, null, null, updateDomainGroupRequest, true);
  }

  static deleteDomainGroup (domainGroupId) {
    return new HttpClient().request(ApiEndpoints.DELETE_DOMAIN_GROUP, {domainGroupId}, null, null, null, true);
  }

  static getTagReport (tagId) {
    return new HttpClient().request(ApiEndpoints.GET_TAG_REPORT, {tagId}, null, null, null, true);
  }

  static getTagAssignmentDetails (tagId) {
    return new HttpClient().request(ApiEndpoints.GET_TAG_ASSIGNMENT_DETAILS, {tagId}, null, null, null, true);
  }

  static getTagDetail (tagId) {
    return new HttpClient().request(ApiEndpoints.GET_TAG_DETAIL, {tagId}, null, null, null, true);
  }

  static getDomainLookup () {
    return new HttpClient().request(ApiEndpoints.GET_DOMAIN_LOOKUP, null, null, null, null, true);
  }

  static getPlanItemsList (searchQuery, pageNumber, pageSize, orderBy, sortBy) {
    searchQuery = searchQuery ? searchQuery : ''
    pageNumber = pageNumber ? pageNumber : 0
    pageSize = pageSize ? pageSize : 10000
    orderBy = orderBy ? orderBy : ''
    sortBy = sortBy ? sortBy : []
    const queryParams = {searchQuery, pageNumber, pageSize, orderBy, sortBy};
    return new HttpClient().request(ApiEndpoints.GET_PLAN_ITEMS, null, queryParams, null, null, true);
  }

  static addPlanItem (payload) {
    return new HttpClient().request(ApiEndpoints.ADD_NEW_PLAN_ITEM, null, null, null, payload, true);
  }

  static updatePlanItem (payload, revampPlanItemId) {
    return new HttpClient().request(ApiEndpoints.UPDATE_PLAN_ITEM, null,{revampPlanItemId}, null, payload, true);
  }

  static deletePlanItem (planItemId) {
    return new HttpClient().request(ApiEndpoints.DELETE_PLAN_ITEM, null , {planItemId}, null, null, true);
  }

  static getRevampTypes () {
    return new HttpClient().request(ApiEndpoints.GET_REVAMP_TYPES, null, null, null, null, true);
  }

  static addRevampType (addRevampRequest) {
    return new HttpClient().request(ApiEndpoints.ADD_REVAMP_TYPE, null, null, null, addRevampRequest, true);
  }

  static updateRevampType (updateRevampRequest, revampTypeId) {
    return new HttpClient().request(ApiEndpoints.UPDATE_REVAMP_TYPE, null, {revampTypeId}, null, updateRevampRequest, true);
  }

  static deleteRevampType (revampTypeId) {
    return new HttpClient().request(ApiEndpoints.DELETE_REVAMP_TYPE, {revampTypeId}, null, null, null, true);
  }

  static getEngagementLevelList (searchQuery, pageNumber, pageSize, orderBy, sortBy) {
    searchQuery = searchQuery ? searchQuery : ''
    pageNumber = pageNumber ? pageNumber : 0
    pageSize = pageSize ? pageSize : 10000
    orderBy = orderBy ? orderBy : ''
    sortBy = sortBy ? sortBy : []
    const queryParams = {searchQuery, pageNumber, pageSize, orderBy, sortBy};
    return new HttpClient().request(ApiEndpoints.GET_ENGAGEMENT_LEVELS, null, queryParams, null, null, true);
  }


  static addEngagementLevel (payload) {
    return new HttpClient().request(ApiEndpoints.ADD_NEW_ENGAGEMENT_LEVEL, null, null, null, payload, true);
  }

  static updateEngagementLevel (payload, levelOfEngagementId) {
    return new HttpClient().request(ApiEndpoints.UPDATE_ENGAGEMENT_LEVEL, {levelOfEngagementId},null, null, payload, true);
  }

  static deleteEngagementLevel (levelOfEngagementId) {
    return new HttpClient().request(ApiEndpoints.DELETE_ENGAGEMENT_LEVEL, {levelOfEngagementId},null, null, null, true);
  }

  static getEvaluationList (searchQuery, pageNumber, pageSize, orderBy, sortBy , status) {
    searchQuery = searchQuery ? searchQuery : ''
    pageNumber = pageNumber ? pageNumber : 0
    pageSize = pageSize ? pageSize : 10000
    orderBy = orderBy ? orderBy : ''
    sortBy = sortBy ? sortBy : []
    status = status ? status :[]
    const queryParams = {searchQuery, pageNumber, pageSize, orderBy, sortBy , status};
    return new HttpClient().request(ApiEndpoints.GET_EVALUATIONS, null, queryParams, null, null, true);
  }

  static addEvaluation (payload) {
    return new HttpClient().request(ApiEndpoints.ADD_NEW_EVALUATION, null, null, null, payload, true);
  }


  static updateEvaluation (payload, evaluationId) {
    return new HttpClient().request(ApiEndpoints.UPDATE_EVALUATION, {evaluationId},null, null, payload, true);
  }

}
