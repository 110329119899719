
import RemoteConfig from './../configurations.json';

/**
 * @author maddy
 * @class AppConfig
 * @Description A class containing configurable parameters (configurations defined in assets/config.json).
 */
const extractDynamicConfigurations = (remoteConfig)=> {
  console.log({remoteConfig})
  if(remoteConfig) {
    let propertySources = remoteConfig.propertySources;
    if(propertySources && propertySources.length>0) {
      const appConfigSource = propertySources.find(propSource=>propSource.name && (propSource.name.includes('confidant-health-web')));
      if(appConfigSource) {
        return appConfigSource.source;
      }
    }
  }
  return null;
};
let applicationConfig = extractDynamicConfigurations(RemoteConfig);
if(!applicationConfig) {
  throw new Error("Unable to find dynamic environment configurations. Please check that the environment specific build scripts");
}
class _AppConfig {

    config;
    constructor() {
        //this.config = require('../../assets/config.json');

      const env = process.env.REACT_APP_ENV;
      if (env === 'dev') {
        this.config = require('../assets/config/config-dev.json');
      } else if (env === 'qa') {
        this.config = require('../assets/config/config-qa.json');
      }else if (env === 'sit1') {
        this.config = require('../assets/config/config-sit1.json');
      }else if (env === 'sit2') {
        this.config = require('../assets/config/config-sit2.json');
      }else if (env === 'staging') {
        this.config = require('../assets/config/config-staging.json');
      }else if (env === 'sit1') {
        this.config = require('../assets/config/config-sit1.json');
      }else if (env === 'sit2') {
        this.config = require('../assets/config/config-sit2.json');
      }else if (env === 'demo') {
        this.config = require('../assets/config/config-demo.json');
      }else if (env === 'prod') {
        this.config = require('../assets/config/config-prod.json');
      }else{
        this.config = require('../assets/config/config-sit1.json');
      }

      if(this.config) {
        this.config.external = applicationConfig;
      } else {
        throw new Error('Local Configuration set not found');
      }
    }
}
// Creating and exporting a proxy singleton instance of _AppConfig Class
const AppConfig = new _AppConfig();
export default AppConfig;
