import React, {Component} from 'react';
import './Conversation.scss';
import JssProvider from 'react-jss/lib/JssProvider';
import {createGenerateClassName} from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Header from "../../../../layout/Header"
import ConversationService from "../../../../services/ConversationService"
import {AlertUtil} from "../../../../utilities/AlertUtil"
import {Loader} from '../../../shared/loader';
import EditIcon from "@material-ui/icons/Edit"
import {DebounceInput} from "react-debounce-input"
import InputBase from "@material-ui/core/InputBase"
import DataTable from "react-data-table-component"
import AddEditConversationModal from "../modal/AddEditConversationModal"
import malecircledark from '../../../../assets/images/male-circle-dark.svg'
import malecirclegrey from '../../../../assets/images/male-circle-grey.svg'
import plusdark from '../../../../assets/images/first-aid-dark.svg'
import plusgrey from '../../../../assets/images/first-aid-grey.svg'
import settingdark from '../../../../assets/images/gear-dark.svg'
import settinggrey from '../../../../assets/images/gear-grey.svg'
import moment from "moment"
import TopSectionComponent from "../../../shared/top-section/TopSectionComponent"
import {BUILDER_CONSTANTS} from "../../../../constants/CommonConstants"
import {S3MediaManager} from "../../../../services/S3MediaManager"

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
  seed: 'app',
});

export class ConversationDraftsList extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: true,
      dataLoading: true,
      conversationsList: [],
      addModalVisible: false,
      searchQuery: '',
      pageSize: 10,
      currentPage: 1,
      reset: false,
      sortBy: [],
      orderBy: '',
      columns: [
        {
          name: "Name",
          selector: row => row.name || "",
          sortable: true
        },
        {
          format: row => (this.getVersionText(row.version)),
          name: "Version",
          selector: row => row.version || "",
          sortable: true
        },
        {
          name: "Assignable By",
          selector: row => row.version || "",
          sortable: false,
          cell: row => <div align="left">

            {row.assignableBy.providerAssignable ? <img className="padding-right-10"
                                                        alt="mencircledark"
                                                        src={malecircledark}/> :
              <img className="padding-right-10" alt="mencirclegrey" src={malecirclegrey}/>
            }
            {row.assignableBy.selfAssignable ? <img className="padding-right-10"
                                                    alt="plusdark" src={plusdark}/> : <img
              className="padding-right-10" alt="plusgrey" src={plusgrey}/>
            }

            {row.assignableBy.systemAssignable ? <img className="padding-right-10"
                                                      alt="settingdark" src={settingdark}/> :
              <img className="padding-right-10" alt="settinggrey" src={settinggrey}/>
            }
          </div>

        },
        {
          format: row => <p
            className="contains-col">{(row.dctCount || 0) + ' DCT' + (row.dctCount > 1 ? 's ' : '  ')}</p>,
          name: "Contains",
          selector: row => row.version || "",
          sortable: false,
        },
        {
          format: row => row.lastUpdated ? moment(row.lastUpdated).format("DD/MM/YYYY") : 'N/A',
          name: "Last Updated",
          selector: "lastUpdated",
          sortable: true,
        }
        , {
          name: "Actions",
          cell: row => <div className="buttons-Wrapper">
            <Button className="edit-OutLined-btn" variant="outlined"
                    onClick={() => {this.editConversation(row)}}>
              <EditIcon style={{color: "#0091F1"}} color="action"/>
            </Button>
          </div>

        }
      ]
    }
    this.form = {}
  }

  componentDidMount = async () => {
    await this.getConversations();
  }

  /**
   * @function getVersionText
   * @description This method returns version text.
   */
  getVersionText = (version) => {
    return "v " + (version ? version : "0") + ".0";
  }

  /**
   * @function getConversations
   * @description This method is used to get all Conversations.
   */
  getConversations = async () => {

    this.setState({
      dataLoading: true
    });
    try {
      const {searchQuery, currentPage, pageSize, orderBy, sortBy} = this.state;
      const response = await ConversationService.getConversationsList(searchQuery.trim(), currentPage - 1, pageSize, orderBy, sortBy, 'DRAFT');
      if (response.errors) {
        AlertUtil.showError(response.errors[0].endUserMessage);
        this.setState({
          isLoading: false,
          dataLoading: false
        });
      } else {
        this.setState({
          conversationsList: response.conversationsList,
          totalPages: response.totalPages,
          totalRecords: response.totalRecords,
          isLoading: false,
          dataLoading: false
        });
      }
    } catch (e) {
      console.log(e);
      AlertUtil.showError("Something went wrong!");
      this.setState({isLoading: false, dataLoading: false});
    }
  }

  closeAddModal = () => {
    this.setState({
      addModalVisible: false
    })
  }

  handlePageChange = page => {
    this.setState({
      currentPage: page,
    }, this.getConversations);
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState({
      pageSize: newPerPage,
    }, this.getConversations)
  };
  /**
   * @function addConversation
   * @description This method is used to add conversation.
   */
  addConversation = async (request, avatar) => {
    this.setState({isLoading: true});
    let conversationRequest = request;
    if (avatar) {
      try {
        const s3Response = await S3MediaManager.uploadChatAvatar(avatar);
        if (s3Response.errors) {
          let hasResponseErrorMessage = s3Response.errors[0].endUserMessage;
          AlertUtil.showError(hasResponseErrorMessage);
        } else {
          conversationRequest = {...conversationRequest, avatar: s3Response.response.key};
        }
      } catch (e) {
        console.log(e);
      }
    }
    const response = await ConversationService.addConversation(conversationRequest);
    if (response.errors) {
      AlertUtil.showError(response.errors[0].endUserMessage);
      this.setState({
        isLoading: false
      });
    } else {
      this.setState({
        addModalVisible: false
      });
      this.props.history.push(`/admin/conversation-builder/conversation/edit/${response.conversationId}`);
    }
  };


  /**
   * @function editConversation
   * @description This method is used to navigate to edit conversation screen.
   */
  editConversation = (conversation) => {
    this.props.history.push(`/admin/conversation-builder/conversation/edit/${conversation.conversationId}`, {
      conversation: conversation,
    });
  };

  /**
   * @function getFieldText
   * @description This method is used to get Field text.
   */
  getFieldText = (fieldName) => {
    switch (fieldName) {
      case "Name" :
        return 'name';
      case "Version" :
        return 'version';
      case "Last Updated" :
        return 'lastModified'
      default :
        return fieldName
    }
  }

  /**
   * @function onSort
   * @description This method is used to sort field values from BE .
   */
  onSort = async (column, sortDirection) => {
    let orderBy = BUILDER_CONSTANTS.SORT_DIRECTIONS[sortDirection];
    const sortBy = this.getFieldText(column.name);
    orderBy = sortBy.length > 0 ? orderBy : ''
    this.setState({
      sortBy, orderBy
    }, () => {
      this.getConversations()
    });
  }

  render () {
    if (this.state.isLoading) {
      return (
        <Loader/>
      );
    }
    const {reset, searchQuery, columns, conversationsList, totalRecords, dataLoading, addModalVisible} = this.state;
    return (
      <JssProvider generateClassName={generateClassName}>
        <div className="main-container">
          <div className="main-body-c">
            <Header/>
            <TopSectionComponent title={'Conversation Drafts'} actionButtons={[
              {
                text: 'Add New',
                onClick: () => {
                  this.setState({
                    addModalVisible: true
                  })
                }
              }, {
                text: 'View Active Conversations',
                onClick: () => {
                  this.props.history.push("/admin/conversation-builder/conversations/list");
                }
              }]}/>
            <div className="content-main">
              <div className="table-actions">

                <div className="search-main">
                  <DebounceInput
                    element={(props) => {

                      return (
                        <InputBase
                          className="search-input"
                          placeholder="Search Drafts by name"
                          autoFocus
                          label="Search Drafts by name"
                          variant="filled"
                          value={props.value}
                          onChange={props.onChange}
                          startAdornment={
                            <InputAdornment position="start">

                              <SearchIcon style={{color: "#475885"}}/>
                            </InputAdornment>
                          }
                        />
                      )
                    }}
                    className="search-input"
                    minLength={1}
                    debounceTimeout={500}
                    value={searchQuery}
                    onChange={event => this.setState({
                      searchQuery: event.target.value,
                      currentPage: 1,
                      reset: !reset
                    }, this.getConversations)}/>
                </div>
              </div>
              <Paper className="table-component-main" elevation={2}>
                <DataTable
                  columns={columns}
                  data={conversationsList}
                  progressPending={dataLoading}
                  persistTableHead
                  keyField={'id'}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRecords}
                  paginationDefaultPage={1}
                  paginationResetDefaultPage={reset}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  sortServer={true}
                  onSort={this.onSort}
                />
              </Paper>

            </div>
          </div>
          <AddEditConversationModal
            openModal={addModalVisible}
            closeModal={this.closeAddModal}
            saveConversation={this.addConversation}
          />
        </div>
      </JssProvider>
    )
  }
}

